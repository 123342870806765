import { APIManager } from './APIManager';
import { ProfileAPIModel } from '@safe-traveler/safe-traveler-data-models';

export class ProfileAPI extends APIManager {

 // POST /profile
 public static async postProfile(profile: ProfileAPIModel) {
  const response = await this.postRequest('/v1/profiles', {}, JSON.stringify(profile));
  return response;
}

  // GET /profile
  public static async getProfile(profileId: string): Promise<ProfileAPIModel> {
    const response = await this.getRequest(`/v1/profiles/${profileId}`);
    return response.data as ProfileAPIModel;
  }  

  // DELETE /profile
  public static async deleteProfile(profileId: string) {
    const response = await this.deleteRequest(`/v1/profiles/${profileId}`);
    return response;
  }
}