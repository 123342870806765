import { Context, useEffect, useReducer } from "react";
import { ProfileAPI } from "../../api/ProfileAPI";
import { ProfileContext } from "./ProfileContext";
import { ProfileContextActionTypes, ProfileContextValue } from "./ProfileContextModels";

const ReactContext: Context<ProfileContextValue> = ProfileContext.getContext();

/**
 * Profile context provider component necessary for holding the reducer and
 * providing context to descendent React components.
 * 
 * All descendent components can access the values within Context.Provider's
 * value attribute using the following code:
 * const { profileState, profileDispatch } =
 *     React.useContext(ProfileContext.getContext());
 */
const ProfileContextProvider = ({children}: any) => {
  const [profileState, profileDispatch] = useReducer(ProfileContext.reducer, ProfileContext.getInitialState());

  useEffect(() => {
    const getProfiles = async () => {
      const response = await ProfileAPI.getProfile("c1"); // change to current logged in user
      profileDispatch({
        type: ProfileContextActionTypes.ADD_PROFILE,
        payload: response,
      });
    }

    getProfiles();
  }, []);

  return (
    <ReactContext.Provider value={{profileState, profileDispatch}}>
      {children}
    </ReactContext.Provider>
  );
}

export default ProfileContextProvider;
