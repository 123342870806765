import {
  TripAPIModel,
  TripHistoryAPIModel
} from "@safe-traveler/safe-traveler-data-models";
import { useContext } from "react";
import { TripContext } from "../../../context/trip-context/TripContext";
import { TripContextActionTypes } from "../../../context/trip-context/TripContextModels";
import Trip from "../trip/Trip";
import "./TripList.css";

interface TripListProps {
  allowDelete?: boolean;
  isHistory?: boolean;
}

const TripList = ({ allowDelete, isHistory }: TripListProps) => {
  const { tripState, tripDispatch } = useContext(TripContext.getContext());

  const renderTrips = (): JSX.Element[] => {
    const tripElements: JSX.Element[] = [];
    const tripData: TripAPIModel[] | TripHistoryAPIModel[] = isHistory
      ? tripState.tripHistoryList
      : tripState.tripList;

    tripData.forEach((trip) => {
      const startDateTime = new Date(trip.startTime);
      const endDateTime = new Date(trip.endTime);

      tripElements.push(
        <Trip
          contactNames={trip.contacts}
          endDateTime={`${endDateTime.toDateString()} ${endDateTime.toTimeString()}`}
          key={`${trip.tripID}`}
          onDelete={
            allowDelete
              ? () => {
                  handleDelete(trip.tripID);
                }
              : undefined
          }
          startDateTime={`${startDateTime.toDateString()} ${startDateTime.toTimeString()}`}
          tripCity={trip.tripCity}
          tripName={trip.tripName}
        />
      );
    });

    return tripElements;
  };

  const handleDelete = (tripID: string) => {
    if (isHistory) {
      tripDispatch({
        type: TripContextActionTypes.DELETE_TRIP_HISTORY,
        payload: tripID,
      });
    } else {
      tripDispatch({
        type: TripContextActionTypes.DELETE_TRIP,
        payload: tripID,
      });
    }
  };

  return <div className="trip-list">{renderTrips()}</div>;
};

export default TripList;
