import { Fragment } from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <Fragment>
      <span className="footer-spacer" />
      <div className="footer">

      </div>
    </Fragment>
  );
}

export default Footer;
