import { Context, createContext } from "react";
import {
  AuthContextActionTypes,
  AuthContextAddTokenAction,
  AuthContextDeleteTokenAction,
  AuthContextState,
  AuthContextValue
} from "./AuthContextModels";

const initialState: AuthContextState = {
  authToken: null,
};

/**
 * Facade class for auth context. This class only contains static methods for
 * accessing and modifying the auth context.
 * 
 * When adding complex functionality to the reducer, create additional static
 * helper methods below and call them within reducer().
 */
export class AuthContext {

  private static Context: Context<AuthContextValue> = createContext({} as AuthContextValue);
  private static initialState = initialState;

  public static getContext(): Context<AuthContextValue> {
    return AuthContext.Context;
  }

  public static getInitialState(): AuthContextState {
    return AuthContext.initialState;
  }

  /**
   * Takes the old state and an action as argument, and returns a new state
   * with the same shape as the old state. Called when a component uses the
   * authDispatch() method declared in AuthContextProvider.
   * 
   * IMPORTANT! The new state must not be the same object reference as the
   * previous state, hence why we create a new state object with JSON parse and
   * JSON stringify.
   * 
   * The action object is an arbitrary object that contains two fields:
   * type: an identifier for the action that should be performed, e.g. update
   * array, remove from array, etc.
   * payload: an arbitrary payload containing information for the reducer to
   * use depending on the action type.
   */
  public static reducer(
    prevState: AuthContextState,
    action:
      AuthContextAddTokenAction |
      AuthContextDeleteTokenAction
  ): AuthContextState {
    const state: AuthContextState = JSON.parse(JSON.stringify(prevState));

    switch (action.type) {
      case AuthContextActionTypes.ADD_TOKEN:
        state.authToken = action.payload;
        break;
      case AuthContextActionTypes.DELETE_TOKEN:
        state.authToken = null;
        break;
      default:
        throw Error(`AuthContext reducer: unsupported action type ${action}`);
    }

    return state;
  }
}
