import { ReviewAPIModel } from "@safe-traveler/safe-traveler-data-models";
import { APIManager } from "./APIManager";

export class ReviewAPI extends APIManager {
  // GET /reviews
  public static async getAllReviews(): Promise<ReviewAPIModel[]> {
    const response = await this.getRequest("/v1/reviews");
    return response.data as ReviewAPIModel[];
  }

  // PUT /reviews
  public static async putReview(review: ReviewAPIModel) {
    const response = await this.putRequest(
      "/v1/reviews",
      {},
      JSON.stringify(review)
    );
    return response;
  }

  // DELETE /reviews/{review-id}
  public static async deleteReview(reviewID: string) {
    const response = await this.deleteRequest(`/v1/reviews/${reviewID}`);
    return response;
  }

  // GET /reviews/{review-id}
  public static async getReview(reviewID: string): Promise<ReviewAPIModel> {
    const response = await this.getRequest(`/v1/reviews/${reviewID}`);
    return response.data as ReviewAPIModel;
  }
}
