import { Dispatch } from 'react';

/**
 * Core types required for auth context to function
 */
// state and dispatch pointers that are exposed by the auth context.
export interface AuthContextValue {
  authState: AuthContextState;
  authDispatch: AuthContextDispatch;
}

// Contains the data of the auth context.
export interface AuthContextState {
  authToken: string | null;
}

// Type of reducer dispatch function for auth context.
export type AuthContextDispatch = Dispatch<
  AuthContextAddTokenAction |
  AuthContextDeleteTokenAction 
>;

// Inputs for auth context dispatch funcntion.
export interface AuthContextAddTokenAction {
  type: AuthContextActionTypes.ADD_TOKEN;
  payload: string;
}

export interface AuthContextDeleteTokenAction {
  type: AuthContextActionTypes.DELETE_TOKEN;
}

export enum AuthContextActionTypes {
  ADD_TOKEN,
  DELETE_TOKEN,
}
