import { TripHistoryAPIModel } from '@safe-traveler/safe-traveler-data-models';
import { APIManager } from './APIManager';

export class TripHistoryAPI extends APIManager {

  // GET /trips-history
  public static async getAllTripsHistory(): Promise<TripHistoryAPIModel[]> {
    const response = await this.getRequest('/v1/trips-history');
    return response.data as TripHistoryAPIModel[];
  }

  // PUT /trips-history
  public static async putTripHistory(tripHistory: TripHistoryAPIModel) {
    const response = await this.putRequest('/v1/trips-history', {}, JSON.stringify(tripHistory));
    return response;
  }

  // DELETE /trips-history/{trip-id}
  public static async deleteTripHistory(tripID: string) {
    const response = await this.deleteRequest(`/v1/trips-history/${tripID}`);
    return response;
  }

  // GET /trips-history/{trip-id}
  public static async getTripHistory(tripID: string): Promise<TripHistoryAPIModel> {
    const response = await this.getRequest(`/v1/trips-history/${tripID}`);
    return response.data as TripHistoryAPIModel;
  }
}
