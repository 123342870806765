import { Context, createContext } from "react";
import { TripHistoryAPI } from "../../api/TripHistoryAPI";
import { TripsAPI } from "../../api/TripsAPI";
import {
  TripContextActionTypes,
  TripContextAddTripAction,
  TripContextAddTripHistoryAction,
  TripContextAddTripsAction,
  TripContextEditTripsAction,
  TripContextAddTripsHistoryAction,
  TripContextDeleteTripAction,
  TripContextDeleteTripHistoryAction,
  TripContextDeleteTripsAction,
  TripContextDeleteTripsHistoryAction,
  TripContextState,
  TripContextSyncTripsAction,
  TripContextSyncTripsHistoryAction,
  TripContextValue
} from "./TripContextModels";

const initialState: TripContextState = {
  tripList: [],
  tripHistoryList: [],
};

/**
 * Facade class for trip context. This class only contains static methods for
 * accessing and modifying the trip context.
 * 
 * When adding complex functionality to the reducer, create additional static
 * helper methods below and call them within reducer().
 */
export class TripContext {

  private static Context: Context<TripContextValue> = createContext({} as TripContextValue);
  private static initialState = initialState;

  public static getContext(): Context<TripContextValue> {
    return TripContext.Context;
  }

  public static getInitialState(): TripContextState {
    return TripContext.initialState;
  }

  /**
   * Takes the old state and an action as argument, and returns a new state
   * with the same shape as the old state. Called when a component uses the
   * tripDispatch() method declared in TripContextProvider.
   * 
   * IMPORTANT! The new state must not be the same object reference as the
   * previous state, hence why we create a new state object with JSON parse and
   * JSON stringify.
   * 
   * The action object is an arbitrary object that contains two fields:
   * type: an identifier for the action that should be performed, e.g. update
   * array, remove from array, etc.
   * payload: an arbitrary payload containing information for the reducer to
   * use depending on the action type.
   */
  public static reducer(
    prevState: TripContextState,
    action:
      TripContextSyncTripsAction |
      TripContextAddTripAction |
      TripContextAddTripsAction |
      TripContextDeleteTripAction |
      TripContextEditTripsAction |
      TripContextDeleteTripsAction |
      TripContextSyncTripsHistoryAction |
      TripContextAddTripHistoryAction |
      TripContextAddTripsHistoryAction |
      TripContextDeleteTripHistoryAction |
      TripContextDeleteTripsHistoryAction
  ): TripContextState {
    const state: TripContextState = JSON.parse(JSON.stringify(prevState));

    switch (action.type) {
      case TripContextActionTypes.SYNC_TRIPS:
        state.tripList = action.payload;
        break;
      case TripContextActionTypes.ADD_TRIP:
        state.tripList.push(action.payload);
        TripsAPI.putTrip(action.payload);
        break;
      case TripContextActionTypes.ADD_TRIPS:
        state.tripList = state.tripList.concat(action.payload);
        break;
      case TripContextActionTypes.DELETE_TRIP:
        const target = state.tripList.filter(e => e.tripID === action.payload)[0];
        state.tripList = state.tripList.filter(e => e.tripID !== action.payload);
        state.tripHistoryList.push({...target, expireDateTime: new Date().getTime() + 60 * 24 * 60 * 60 * 1000});
        TripsAPI.deleteTrip(action.payload);
        TripHistoryAPI.putTripHistory({...target, expireDateTime: 0});
        break;
      case TripContextActionTypes.DELETE_TRIPS:
        action.payload.forEach(deleteTripID => {
          state.tripList = state.tripList.filter(e => e.tripID !== deleteTripID);
        });
        break;
      case TripContextActionTypes.SYNC_TRIPS_HISTORY:
        state.tripHistoryList = action.payload;
        break;
      case TripContextActionTypes.ADD_TRIP_HISTORY:
        state.tripHistoryList.push(action.payload);
        break;
      case TripContextActionTypes.ADD_TRIPS_HISTORY:
        state.tripHistoryList = state.tripHistoryList.concat(action.payload);
        break;
      case TripContextActionTypes.DELETE_TRIP_HISTORY:
        state.tripHistoryList = state.tripHistoryList.filter(e => e.tripID !== action.payload);
        TripHistoryAPI.deleteTripHistory(action.payload);
        break;
      case TripContextActionTypes.DELETE_TRIPS_HISTORY:
        action.payload.forEach(deleteTripID => {
          state.tripHistoryList = state.tripHistoryList.filter(e => e.tripID !== deleteTripID);
          TripHistoryAPI.deleteTripHistory(deleteTripID);
        });
        break;
      case TripContextActionTypes.EDIT_TRIP:
        state.tripList = state.tripList.map((e) => e.tripID !== action.payload.tripID ? e : action.payload);
        TripsAPI.putTrip(action.payload);
        break;
      default:
        throw Error(`TripContext reducer: unsupported action type ${action}`);
    }

    return state;
  }
}
