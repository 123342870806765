import { Fragment, useContext } from 'react';
import { AuthContext } from '../../context/auth-context/AuthContext';
import { AuthContextActionTypes } from '../../context/auth-context/AuthContextModels';
import './SidenavFooter.css';

export const SidenavFooter = () => {
  const { authState, authDispatch } = useContext(AuthContext.getContext());

  const handleSignOut = () => {
    authDispatch({
      type: AuthContextActionTypes.DELETE_TOKEN,
    })
  }

  return (
    <Fragment>
      {
        authState.authToken ?
        <span className='sidenav-footer' onClick={handleSignOut}>
          Sign out
        </span> :
        <a
          className='sidenav-footer'
          href="https://safe-traveler.auth.us-east-1.amazoncognito.com/login?client_id=7m0qg6vbbgpj96a0i6hh1qosia&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://www.safetraveler.me/signin-callback"
        >
          Sign in
        </a>
      }
    </Fragment>
  );
}
