import { Button, PageContent } from '@jam-dev/starlight-react';
import { Fragment } from 'react';
import Footer from '../../Components/common/footer/Footer';
import './SettingsPage.css';

const SettingsPage = () => {
  const setDarkTheme = () => {
    const style = document.documentElement.style;

    style.setProperty('--accent-color', 'rgb(211, 188, 142)');
    style.setProperty('--accent-color-faded', 'rgba(211, 188, 142, 0.35)');
    style.setProperty('--accent-color-extra-faded', 'rgba(211, 188, 142, 0.15)');
    style.setProperty('--accent-color-extra-faded-translucent', 'rgba(211, 188, 142, 0.15)');
    style.setProperty('--bg-color', 'rgb(32, 32, 39)');
    style.setProperty('--bg-color-translucent', 'rgba(32, 32, 39, 0.15)');

    style.setProperty('--acrylic-accent-color', 'rgb(211, 188, 142)');
    style.setProperty('--acrylic-accent-color-translucent', 'rgba(211, 188, 142, 0.35)');
    style.setProperty('--acrylic-accent-color-translucent-dark', 'rgba(211, 188, 142, 0.35)');
  }

  return (
    <Fragment>
      <PageContent>
        <h1>Settings</h1>
        <h2>Theme</h2>
        <Button>Light</Button>
        <Button onClick={setDarkTheme}>Dark</Button>
        <hr />
        <h2>Animations</h2>
        <Button>Enable</Button>
        <Button>Disable</Button>
        <hr />
        <h2>Log out</h2>
        <Button>Log out</Button>
      </PageContent>
      <Footer />
    </Fragment>
  );
}

export default SettingsPage;
