import {Context, createContext} from "react";
import {
    ItineraryContextActionTypes,
    ItineraryContextAddEventAction,
    ItineraryContextAddEventsAction,
    ItineraryContextDeleteEventAction,
    ItineraryContextDeleteEventsAction,
    ItineraryContextReplaceEventAction,
    ItineraryContextState,
    ItineraryContextSyncEventsAction,
    ItineraryContextValue
} from "./ItineraryContextModels";
import {ItineraryAPI} from "../../api/ItineraryAPI";

const initialState: ItineraryContextState = {
    itineraryList: []
};

/**
 * Facade class for itinerary context. This class only contains static methods for
 * accessing and modifying the itinerary context.
 *
 * When adding complex functionality to the reducer, create additional static
 * helper methods below and call them within reducer().
 */
export class ItineraryContext {

    private static Context: Context<ItineraryContextValue> = createContext({} as ItineraryContextValue);
    private static initialState = initialState;

    public static getContext(): Context<ItineraryContextValue> {
        return ItineraryContext.Context;
    }

    public static getInitialState(): ItineraryContextState {
        return ItineraryContext.initialState;
    }

    /**
     * Takes the old state and an action as argument, and returns a new state
     * with the same shape as the old state. Called when a component uses the
     * itineraryDispatch() method declared in ItineraryContextProvider.
     *
     * IMPORTANT! The new state must not be the same object reference as the
     * previous state, hence why we create a new state object with JSON parse and
     * JSON stringify.
     *
     * The action object is an arbitrary object that contains two fields:
     * type: an identifier for the action that should be performed, e.g. update
     * array, remove from array, etc.
     * payload: an arbitrary payload containing information for the reducer to
     * use depending on the action type.
     */
    public static reducer(
        prevState: ItineraryContextState,
        action:
            ItineraryContextSyncEventsAction |
            ItineraryContextAddEventAction |
            ItineraryContextAddEventsAction |
            ItineraryContextDeleteEventAction |
            ItineraryContextDeleteEventsAction |
            ItineraryContextReplaceEventAction
    ): ItineraryContextState {
        const state: ItineraryContextState = JSON.parse(JSON.stringify(prevState));

        switch (action.type) {
            case ItineraryContextActionTypes.SYNC_EVENTS:
                state.itineraryList = action.payload;
                break;
            case ItineraryContextActionTypes.ADD_EVENT:
                state.itineraryList.push(action.payload);
                ItineraryAPI.putEvent(action.payload);
                break;
            case ItineraryContextActionTypes.ADD_EVENTS:
                state.itineraryList = state.itineraryList.concat(action.payload);
                break;
            case ItineraryContextActionTypes.DELETE_EVENT:
                state.itineraryList = state.itineraryList.filter(e => e.eventId !== action.payload);
                ItineraryAPI.deleteEvent(action.payload);
                break;
            case ItineraryContextActionTypes.DELETE_EVENTS:
                action.payload.forEach((deleteEventID: string) => {
                    state.itineraryList = state.itineraryList.filter(e => e.eventId !== deleteEventID);
                });
                break;
            case ItineraryContextActionTypes.REPLACE_EVENT:
                state.itineraryList = state.itineraryList.filter(e => e.eventId !== action.payload.eventId);
                state.itineraryList.push(action.payload);
                ItineraryAPI.putEvent(action.payload);
                break;
            default:
                throw Error(`ItineraryContext reducer: unsupported action type ${action}`);
        }

        return state;
    }
}
