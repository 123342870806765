import { Fragment, useState, useContext } from "react";
import { PageContent } from "@jam-dev/starlight-react";
import { FormContainer, FormRow, FormSubmit, FormText, FormCheckbox } from '@safe-traveler/react-component-lib';
import { useNavigate, useParams } from "react-router-dom";
import { ContactContext } from '../../context/contact-context/ContactContext';
import { ContactAPIModel } from '@safe-traveler/safe-traveler-data-models';
import { ContactContextActionTypes } from '../../context/contact-context/ContactContextModels';
import friendsIllustration from '../../assets/illustration/undraw_coffee_with_friends.svg';
import Footer from "../../Components/common/footer/Footer";
import { ProfileContext } from "../../context/profile-context/ProfileContext";

export default function ModifyContact() {
  let navigate = useNavigate();
  const { contactState, contactDispatch } = useContext(ContactContext.getContext());
  const { profileState, profileDispatch } = useContext(ProfileContext.getContext());

  const { contactId } = useParams();
  const CONTACT_ID = contactId || "";

  var contactSelected = contactState.contacts.find((contact) => {
    return contact.contactId === CONTACT_ID 
  });

  if (contactSelected == undefined) {
    contactSelected = {
      contactId : "",
      creatorId : "",
      firstName : "",
      lastName : "",
      email : "",
      phoneNumber : "",
      favorite : false,
      blocked : false,
      contactEmail : false,
      contactPhone : false
    }
  }

  const [blocked, setBlocked] = useState(contactSelected.blocked);
  const [contactEmail, setContactEmail] = useState(contactSelected.contactEmail);
  const [contactPhone, setContactPhone] = useState(contactSelected.contactPhone);
  const [email, setEmail] = useState(contactSelected.email);
  const [favorite, setFavorite] = useState(contactSelected.favorite);
  const [firstName, setFirstName] = useState(contactSelected.firstName);
  const [lastName, setLastName]= useState(contactSelected.lastName);
  const [phoneNumber, setPhoneNumber] = useState(contactSelected.phoneNumber);

  const handleSubmit = async () => {
    const modifiedContact: ContactAPIModel = {
      contactId : CONTACT_ID,
      creatorId : profileState.profile[0].profileId,
      firstName : firstName,
      lastName : lastName,
      email : email,
      phoneNumber : phoneNumber,
      favorite : favorite,
      blocked : blocked,
      contactEmail : contactEmail,
      contactPhone : contactPhone
    }

    contactDispatch({
      type: ContactContextActionTypes.EDIT_CONTACT,
      payload: {
        contact: modifiedContact
      }
    });
    navigate("/contacts");
  }
  
  return (
    <Fragment>
      <PageContent>
        <h1>Modify Contacts</h1>
        <img alt='Contacts art' className='contacts-page-illustration' src={friendsIllustration} />
        <FormContainer onSubmit={handleSubmit}>
          <FormRow>
            <FormText id='contact-modify-first-name' initialValue={contactSelected?.firstName} label='First Name' onChange={setFirstName} required/>
          </FormRow>
          <FormRow>
            <FormText id='contact-modify-last-name' initialValue={contactSelected?.lastName} label='Last Name' onChange={setLastName} required/>
          </FormRow>
          <FormRow>
            <FormText id='contact-modify-phoneNumber' initialValue={contactSelected?.phoneNumber} label='Phone Number' onChange={setPhoneNumber} required pattern='[0-9]{3}[0-9]{3}[0-9]{4}'/>
          </FormRow>
          <FormRow>
            <FormText id='contact-modify-email' initialValue={contactSelected?.email} label='Email' onChange={setEmail} required pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}'/>
          </FormRow>
          <FormRow>
            <FormCheckbox id='contact-modify-fav' initialChecked={contactSelected?.favorite} label='Set as favorite?' onCheck={setFavorite}></FormCheckbox>
          </FormRow>
          <FormRow>
            <FormCheckbox id='contact-modify-block' initialChecked={contactSelected?.blocked} label='Block contact?' onCheck={setBlocked}></FormCheckbox>
          </FormRow>
          <FormRow>
            <FormSubmit />
          </FormRow>
        </FormContainer>
      </PageContent>
      <Footer />
    </Fragment>
  );
}