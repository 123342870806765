import { TripAPIModel, TripHistoryAPIModel } from '@safe-traveler/safe-traveler-data-models';
import { Dispatch } from 'react';

/**
 * Core types required for trip context to function
 */
// state and dispatch pointers that are exposed by the trip context.
export interface TripContextValue {
  tripState: TripContextState;
  tripDispatch: TripContextDispatch;
}

// Contains the data of the trip context.
export interface TripContextState {
  tripList: TripAPIModel[],
  tripHistoryList: TripHistoryAPIModel[],
}

// Type of reducer dispatch function for trip context.
export type TripContextDispatch = Dispatch<
  TripContextSyncTripsAction |
  TripContextAddTripAction |
  TripContextAddTripsAction |
  TripContextDeleteTripAction |
  TripContextDeleteTripsAction |
  TripContextEditTripsAction |
  TripContextSyncTripsHistoryAction |
  TripContextAddTripHistoryAction |
  TripContextAddTripsHistoryAction |
  TripContextDeleteTripHistoryAction |
  TripContextDeleteTripsHistoryAction
>;

// Inputs for trip context dispatch funcntion.
export interface TripContextSyncTripsAction {
  type: TripContextActionTypes.SYNC_TRIPS;
  payload: TripAPIModel[];
}

export interface TripContextAddTripAction {
  type: TripContextActionTypes.ADD_TRIP;
  payload: TripAPIModel;
}

export interface TripContextAddTripsAction {
  type: TripContextActionTypes.ADD_TRIPS;
  payload: TripAPIModel[];
}

export interface TripContextDeleteTripAction {
  type: TripContextActionTypes.DELETE_TRIP;
  payload: string;
}

export interface TripContextDeleteTripsAction {
  type: TripContextActionTypes.DELETE_TRIPS;
  payload: string[];
}

export interface TripContextEditTripsAction {
  type: TripContextActionTypes.EDIT_TRIP;
  payload: TripAPIModel;
}

export interface TripContextSyncTripsHistoryAction {
  type: TripContextActionTypes.SYNC_TRIPS_HISTORY;
  payload: TripHistoryAPIModel[];
}

export interface TripContextAddTripHistoryAction {
  type: TripContextActionTypes.ADD_TRIP_HISTORY;
  payload: TripHistoryAPIModel;
}

export interface TripContextAddTripsHistoryAction {
  type: TripContextActionTypes.ADD_TRIPS_HISTORY;
  payload: TripHistoryAPIModel[];
}

export interface TripContextDeleteTripHistoryAction {
  type: TripContextActionTypes.DELETE_TRIP_HISTORY;
  payload: string;
}

export interface TripContextDeleteTripsHistoryAction {
  type: TripContextActionTypes.DELETE_TRIPS_HISTORY;
  payload: string[];
}

export enum TripContextActionTypes {
  SYNC_TRIPS,
  ADD_TRIP,
  ADD_TRIPS,
  DELETE_TRIP,
  EDIT_TRIP,
  DELETE_TRIPS,
  SYNC_TRIPS_HISTORY,
  ADD_TRIP_HISTORY,
  ADD_TRIPS_HISTORY,
  DELETE_TRIP_HISTORY,
  DELETE_TRIPS_HISTORY,
}
