import { PagePanel } from '@jam-dev/starlight-react';
import { FormContainer, FormRow, FormSubmit, FormText } from '@safe-traveler/react-component-lib';
import { ReactNode, useContext, useState } from 'react';
import friendsIllustration from '../../../assets/illustration/undraw_working_remotely.svg';
import { ProfileContext } from '../../../context/profile-context/ProfileContext';
import { ProfileContextActionTypes } from '../../../context/profile-context/ProfileContextModels';
import './ProfileEditPanel.css';
import { ProfileAPIModel } from '@safe-traveler/safe-traveler-data-models';

interface ProfileEditPanelProps {
  onClose?: () => any;
  open?: boolean;
  title: ReactNode;
}

export const ProfileEditPanel = ({onClose, open, title}: ProfileEditPanelProps) => {
  const {profileState, profileDispatch} = useContext(ProfileContext.getContext());
  const [firstName, setFirstName] = useState(profileState.profile[0].firstName);
  const [lastName, setLastName]= useState(profileState.profile[0].lastName);
  const [email, setEmail] = useState(profileState.profile[0].email);
  const [phoneNumber, setPhoneNumber] = useState(profileState.profile[0].phoneNumber);

  const profileId = profileState.profile[0].profileId;

  const handleSubmit = async () => {
    if (onClose) onClose();

    const editedProfile: ProfileAPIModel = {
      profileId: profileId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber
    }
    profileDispatch({
      type: ProfileContextActionTypes.EDIT_PROFILE,
      payload: editedProfile
    });
  }
  
  return (
    <PagePanel
      onClose={onClose}
      open={open ? true : false}
      title={title}
    >
      <img alt='Profile art' className='profile-page-illustration' src={friendsIllustration} />
      <FormContainer onSubmit={handleSubmit}>
        <FormRow>
          <FormText id='profile-edit-first-name' initialValue={profileState.profile[0].firstName} label='First Name' onChange={setFirstName} required/>
        </FormRow>
        <FormRow>
          <FormText id='profile-edit-last-name' initialValue={profileState.profile[0].lastName} label='Last Name' onChange={setLastName} required/>
        </FormRow>
        <FormRow>
          <FormText id='profile-edit-phoneNumber' initialValue={profileState.profile[0].phoneNumber} label='Phone Number' onChange={setPhoneNumber} required pattern='[0-9]{3}[0-9]{3}[0-9]{4}'/>
        </FormRow>
        <FormRow>
          <FormText id='profile-edit-email' initialValue={profileState.profile[0].email} label='Email' onChange={setEmail} required pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}'/>
        </FormRow>
        <FormRow>
          <FormSubmit />
        </FormRow>
      </FormContainer>
    </PagePanel>
  );
}
