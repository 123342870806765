import axios from "axios";

export interface APIQueryStrings {
  key: string;
  value: string;
}

export class APIManager {
  private static readonly baseURL: string = 'https://s236sqpkpk.execute-api.us-east-1.amazonaws.com/prod';

  protected static async deleteRequest(path: string, queryStringParameters?: {[key: string]: string}, body?: string) {
    const response = axios.delete(`${APIManager.baseURL}${path}`, {
      data: body,
      params: queryStringParameters,
    });

    return response;
  }

  protected static async getRequest(path: string, queryStringParameters?: {[key: string]: string}, body?: string) {
    const response = axios.get(`${APIManager.baseURL}${path}`, {
      data: body,
      params: queryStringParameters,
    });

    return response;
  }

  protected static async putRequest(path: string, queryStringParameters?: {[key: string]: string}, body?: string) {
    const response = axios.put(`${APIManager.baseURL}${path}`, {
      data: body,
      params: queryStringParameters
    });
    return response;
  }

  protected static async postRequest(path: string, queryStringParameters?: {[key: string]: string}, body?: string) {
    const response = axios.post(`${APIManager.baseURL}${path}`, {
      data: body,
      params: queryStringParameters
    });
    return response;
  }
}