import { Context, createContext } from "react";
import {
  SidenavContextActionTypes,
  SidenavContextReducerAction,
  SidenavContextState,
  SidenavContextValue,
} from "./SidenavContextModels";

const initialState: SidenavContextState = {
  isOpen: document.documentElement.clientWidth >= 768 ? true : false,
  routes: [
    {
      route: "/",
      routeName: "Home",
    },
    {
      route: "/login",
      routeName: "Login",
    },
    {
      route: "/signup",
      routeName: "Signup",
    },
    {
      route: "/contacts",
      routeName: "Contacts",
    },
    {
      route: "/addContacts",
      routeName: "Add Contacts",
    },
    {
      route: "/map",
      routeName: "Map",
    },
    {
      route: "/profile",
      routeName: "Profile",
    },
    {
      route: "/editProfile",
      routeName: "Edit Profile",
    },
    {
      route: "/addTrip",
      routeName: "Add Trip",
    },
    {
      route: "/modifyTrip",
      routeName: "Modify Trip",
    },
    {
      route: "/trips",
      routeName: "My Trips",
    },
    {
      route: "/trip/current",
      routeName: "Current Trip",
    },
    {
      route: "/createUrl",
      routeName: "Create URL",
    },
    {
      route: "/viewUrls",
      routeName: "View URLs",
    },
    {
      route: "/dev-page",
      routeName: "Dev Page",
    },
    // adding this here for testing purposes, remove later
    {
      route: "/contact-view",
      routeName: "Contact View",
    },
    {
      route: "/addReview",
      routeName: "Add Review",
    },
    {
      route: "tripHistory",
      routeName: "Trip History",
    },
    {
      route: "/itinerary",
      routeName: "Itinerary"
    }
  ],
};

/**
 * Facade class for sidenav context. This class only contains static methods for
 * accessing and modifying the sidenav context.
 *
 * When adding complex functionality to the reducer, create additional static
 * helper methods below and call them within reducer().
 */
export class SidenavContext {
  private static Context: Context<SidenavContextValue> = createContext(
    {} as SidenavContextValue
  );
  private static initialState = initialState;

  public static getContext(): Context<SidenavContextValue> {
    return SidenavContext.Context;
  }

  public static getInitialState(): SidenavContextState {
    return SidenavContext.initialState;
  }

  /**
   * Takes the old state and an action as argument, and returns a new state
   * with the same shape as the old state. Called when a component uses the
   * sidenavDispatch() method declared in SidenavContextProvider.
   *
   * IMPORTANT! The new state must not be the same object reference as the
   * previous state, hence why we create a new state object with JSON parse and
   * JSON stringify.
   *
   * The action object is an arbitrary object that contains two fields:
   * type: an identifier for the action that should be performed, e.g. update
   * array, remove from array, etc.
   * payload: an arbitrary payload containing information for the reducer to
   * use depending on the action type.
   */
  public static reducer(
    prevState: SidenavContextState,
    action: SidenavContextReducerAction
  ): SidenavContextState {
    const state: SidenavContextState = JSON.parse(JSON.stringify(prevState));

    switch (action.type) {
      case SidenavContextActionTypes.OPEN_SIDENAV:
        state.isOpen = true;
        break;
      case SidenavContextActionTypes.CLOSE_SIDENAV:
        state.isOpen = false;
        break;
      case SidenavContextActionTypes.TOGGLE_SIDENAV:
        state.isOpen = !state.isOpen;
        break;
      default:
        throw Error(
          `SidenavContext reducer: unsupported action type ${action.type}`
        );
    }

    return state;
  }
}
