import './Search.css';

interface SearchProps {
  handleChange: (e: any) => any;
  searchInput: string;
}

const Search = ({handleChange, searchInput}: SearchProps) => {
  return (
    <input 
        className='search'
        type = "searchBar"
        placeholder = "Search"
        onChange = {handleChange}
        value = {searchInput}
    />
  );
}
export default Search;


