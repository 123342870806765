import { ReactNode } from "react";
import accountCircleIcon from "../assets/icons/account-circle.svg";
import accountIcon from "../assets/icons/account.svg";
import codeTagsIcon from "../assets/icons/code-tags.svg";
import cogIcon from "../assets/icons/cog.svg";
import compassIcon from "../assets/icons/compass.svg";
import historyIcon from "../assets/icons/history.svg";
import homeIcon from "../assets/icons/home.svg";
import mapMarkerIcon from "../assets/icons/map-marker.svg";
import mapIcon from "../assets/icons/map.svg";
import checklistIcon from "../assets/icons/checklist.svg";
import { ContactsPage } from "../route/contacts-page/ContactsPage";
import { CurrentTripPage } from "../route/current-trip-page/CurrentTripPage";
import DevPage from "../route/dev-page/DevPage";
import { HomePage } from "../route/home-page/HomePage";
import LocationReviewPage from "../route/location-review-page/LocationReviewPage";
import ReviewPage from "../route/location-review-page/review-page/MyReviewPage";
import { ProfilePage } from "../route/profile-page/ProfilePage";
import SettingsPage from "../route/settings-page/SettingsPage";
import { TripsHistoryPage } from "../route/trip-history-page/TripsHistoryPage";
import { TripsPage } from "../route/trips-page/TripsPage";
import ContactModify  from "../route/contacts-page/ContactModify";
import ShareTripPage from "../route/share-trip-page/ShareTripPage";
import { ItineraryPage } from "../route/itinerary-page/ItineraryPage";

export interface PageData {
  component: ReactNode;
  pageRoute: string;
  sidenavConfig?: {
    icon: string;
    pageName: string;
  };
}

export const pages: PageData[] = [
  {
    component: <HomePage />,
    pageRoute: "/",
    sidenavConfig: {
      icon: homeIcon,
      pageName: "Home",
    },
  },
  {
    component: <CurrentTripPage />,
    pageRoute: "/current-trip",
    sidenavConfig: {
      icon: mapIcon,
      pageName: "Current Trip",
    },
  },
  {
    component: <TripsPage />,
    pageRoute: "/trips",
    sidenavConfig: {
      icon: mapMarkerIcon,
      pageName: "Trips",
    },
  },
  {
    component: <TripsHistoryPage />,
    pageRoute: "/trips-history",
    sidenavConfig: {
      icon: historyIcon,
      pageName: "Trips History",
    },
  },
  {
    component: <ItineraryPage />,
    pageRoute: "/itinerary",
    sidenavConfig: {
      icon: checklistIcon,
      pageName: "Itinerary",
    },
  },
  {
    component: <ContactModify />,
    pageRoute: "/contacts/:contactId",
  },
  {
    component: <ContactsPage />,
    pageRoute: "/contacts",
    sidenavConfig: {
      icon: accountIcon,
      pageName: "Contacts",
    },
  },
  {
    component: <LocationReviewPage />,
    pageRoute: "/location-review",
    sidenavConfig: {
      icon: compassIcon,
      pageName: "Location Review",
    },
  },
  {
    component: <ProfilePage />,
    pageRoute: "/profile",
    sidenavConfig: {
      icon: accountCircleIcon,
      pageName: "My Profile",
    },
  },
  {
    component: <SettingsPage />,
    pageRoute: "/settings",
    sidenavConfig: {
      icon: cogIcon,
      pageName: "Settings",
    },
  },
  {
    component: <ShareTripPage />,
    pageRoute: "/shareTrip",
    sidenavConfig: {
      icon: cogIcon,
      pageName: "ShareTrip",
    },
  },
  {
    component: <DevPage />,
    pageRoute: "/dev-page",
    sidenavConfig: {
      icon: codeTagsIcon,
      pageName: "Dev Page",
    },
  },
  {
    component: <ReviewPage />,
    pageRoute: "/reviews"
  },
];
