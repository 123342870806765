import "./Tag.css";

export interface TagProps {
  value: string;
}

const Tag = ({value}: TagProps) => {
  return (
    <div className="tag">{value}</div>
  );
}

export default Tag;
