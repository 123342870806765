import axios from "axios";
import { useEffect, useRef, useState, useContext } from "react";
import { PageContent } from "@jam-dev/starlight-react";
import MapComponent from "../../Components/map/MapComponent";
import { ProfileContext } from "../../context/profile-context/ProfileContext";
import { TripContext } from "../../context/trip-context/TripContext";
import "./ContactView.css";

const tripsURL = `https://twz8i1642g.execute-api.us-east-1.amazonaws.com/prod/v1/trips`;
const profilesURL = `https://twz8i1642g.execute-api.us-east-1.amazonaws.com/prod/v1/profiles`;

const axiosTripClient = axios.create({
  baseURL: tripsURL,
});

const axiosProfileClient = axios.create({
  baseURL: profilesURL,
});

// props needed: profile id, trip id
interface ContactViewProps {
  profileId: string;
  tripId: string;
}

let checkIns = [
  { lat: 40.42631496422626, lng: -86.9241860412414 },

  { lat: 40.42976669903312, lng: -86.91978721648304 },

  { lat: 40.42197791810427, lng: -86.91676423559183 },

  { lat: 40.422654993919764, lng: -86.9237140074485 },

  { lat: 40.4233090605779, lng: -86.9111287170466 },
];

export default function ContactView({ profileId, tripId }: ContactViewProps) {
  const mapComponentRef = useRef();
  // need to display trip name, user that shared location, time and address of last check-in
  const { profileState, profileDispatch } = useContext(
    ProfileContext.getContext()
  );
  const { tripState, tripDispatch } = useContext(TripContext.getContext());
  var tripData = tripState.tripList.find((contact) => {
    return contact.tripID === tripId;
  });
  // const [lastCheckInTime, setLastCheckInTime] = useState("");
  // const [lastCheckInDate, setLastCheckInDate] = useState("");
  // const [tripAddress, setTripAddress] = useState("");
  // const [tranDisplay, setTranDisplay] = useState<any>(null);
  // const [lodgDisplay, setLodgDisplay] = useState<any>(null);
  const [checkInLocations, setCheckInLocations] = useState<any[]>(checkIns);

  const mapComponentProps = {
    ref: mapComponentRef,
    showCheckInLocations: true,
    viewerMode: true,
    checkInLocations: checkInLocations,
  };

  // useEffect(() => {
  //   console.log(profileId + " " + tripId);
  //   async function getTripData() {
  //     const axiosResponse = await axiosTripClient.get(`${tripId}`);
  //     console.log("Trips Response: ");
  //     console.log(axiosResponse.data);
  //     setTripData(axiosResponse.data);
  //   }
  //   async function getProfileData() {
  //     const axiosResponse = await axiosProfileClient.get(
  //       `${profilesURL}/${profileId}`
  //     );
  //     setTripUser(
  //       axiosResponse.data[0].firstName + " " + axiosResponse.data[0].lastName
  //     );
  //   }
  //   getTripData();
  //   getProfileData();
  // }, []);

  //parses transport method string and stores it in the appropriate variables
  const parseTransport = (transportStr: string) => {
    if (transportStr.indexOf("%") === -1) {
      return <p>No transportation details shared.</p>;
    }
    let transportInfo = transportStr.split("%");
    console.log("Trip Info: ");
    console.log(transportInfo);
    if (transportInfo[0] === "RS" || transportInfo[0] === "TA") {
      let tra = "Rideshare";
      if (transportInfo[0] === "TA") {
        tra = "Taxi";
      }
      return (
        <div>
          <p>Transport Method: {tra}</p>
          <p>License: {transportInfo[1]}</p>
        </div>
      );
    } else if (transportInfo[0] === "RC") {
      return (
        <div>
          <p>Transport Method: Rental Car</p>
          <p>License: {transportInfo[1]}</p>
          <p>Company: {transportInfo[2]}</p>
        </div>
      );
    } else if (transportInfo[0] === "BU") {
      return (
        <div>
          <p>Transport Method: Bus</p>
          <p>Bus Number: {transportInfo[1]}</p>
          <p>Bus Start: {transportInfo[2]}</p>
          <p>Bus End: {transportInfo[3]}</p>
        </div>
      );
    } else if (transportInfo[0] === "FL" || transportInfo[0] === "RTFL") {
      if (transportInfo[0] === "RTFL") {
        return (
          <div>
            <p>Transport Method: Flight</p>
            <p>Airline: {transportInfo[1]}</p>
            <p>Flight Number: {transportInfo[2]}</p>
            <p>Flight Start: {transportInfo[3]}</p>
            <p>Flight End: {transportInfo[4]}</p>
            <p>Returning Flight Number: {transportInfo[5]}</p>
            <p>Returning Flight Start: {transportInfo[6]}</p>
            <p>Returning Flight End: {transportInfo[7]}</p>
          </div>
        );
      } else {
        return (
          <div>
            <p>Transport Method: Flight</p>
            <p>Airline: {transportInfo[1]}</p>
            <p>Flight Number: {transportInfo[2]}</p>
            <p>Flight Start: {transportInfo[3]}</p>
            <p>Flight End: {transportInfo[4]}</p>
          </div>
        );
      }
    }
  };

  const parseLodging = (lodgingStr: string) => {
    if (lodgingStr.indexOf("%") === -1) {
      return <p>Lodging details were not shared with you.</p>;
    }
    let lodgingInfo = lodgingStr.split("%");
    if (lodgingInfo[0] === "PD") {
      //share details
      return <p>Lodging details were not shared with you.</p>;
    } else {
      var lodgingType = "";
      if (lodgingInfo[1] === "RH") {
        lodgingType = "Rental Home";
      }
      if (lodgingInfo[1] === "HO") {
        lodgingType = "Hotel/Motel/Inn";
      }
      if (lodgingInfo[1] === "CA") {
        lodgingType = "Campsite";
      }
      if (lodgingInfo[1] === "FR") {
        lodgingType = "Staying with Friends/Family";
      }
      return (
        <p>
          Lodging Type: {lodgingType}
          <br />
          Lodging Name: {lodgingInfo[2]}
          <br />
          Lodging Address: {lodgingInfo[3]}
          <br />
          Lodging Phone Number: {lodgingInfo[4]}
        </p>
      );
    }
  };

  // useEffect(() => {
  //   if (tripData.length > 0) {
  //     setTripName(tripData[0].tripName);
  //     setTripAddress(tripData[0].tripAddress);
  //     setTranDisplay(parseTransport(tripData[0].transportMethod));
  //     setLodgDisplay(parseLodging(tripData[0].lodgingDetails));
  //     /*if (tripData[0].lodgingDetails.indexOf("%") === -1) {
  //       setLodging("NO");
  //     } else {
  //       parseLodging(tripData[0].lodgingDetails);
  //     }*/
  //     console.log(tripData[0]);

  // setLastCheckInDate(
  //   new Intl.DateTimeFormat("en-US", {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //   }).format(tripData[0].lastCheckInDateTime)
  // );

  //     setLastCheckInTime(
  // new Intl.DateTimeFormat("en-US", {
  //   hour: "2-digit",
  //   minute: "2-digit",
  // }).format(tripData[0].lastCheckInDateTime)
  //     );

  //     let res: any[] = [];
  //     tripData[0].previousCoords.forEach((e: any) => {
  //       let coords = e.S.split("%");
  //       res.push({
  //         lat: parseFloat(coords[0]),
  //         lng: parseFloat(coords[1]),
  //       });
  //     });

  //     setCheckInLocations(res);
  //   }
  // }, [tripData]);

  return (
    <PageContent>
      <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          justifyItems: "center",
          alignItems: "center",
          marginTop: "5%",
        }}
      >
        <h2>{`${tripData?.tripName} shared to you by ${profileState.profile[0].firstName} ${profileState.profile[0].lastName}`}</h2>
        <br />
        <div className="location-review-page-map">
          <MapComponent {...mapComponentProps} />
        </div>
        <br />
        <p>{`Last checked in at ${
          tripData?.tripCity
        } on ${new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(tripData?.lastCheckInDateTime)} at ${new Intl.DateTimeFormat(
          "en-US",
          {
            hour: "2-digit",
            minute: "2-digit",
          }
        ).format(tripData?.lastCheckInDateTime)}`}</p>
        {/* <p><b>{`Lodging Details: `}</b>{tranDisplay}</p>
        <p><b>{`Transportation Details: `}</b>{lodgDisplay}</p> */}
      </div>
    </PageContent>
  );
}
