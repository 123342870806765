import {Button, Icon, PageContent} from "@jam-dev/starlight-react";
import {FormDate, FormText, FormContainer, FormSubmit} from "@safe-traveler/react-component-lib";
import {ItineraryAPIModel, TripAPIModel} from "@safe-traveler/safe-traveler-data-models";
import React, {Fragment, useContext, useEffect, useState} from "react";
import plusIcon from '../../assets/icons/plus.svg';
import Footer from "../../Components/common/footer/Footer";
import {ContactContext} from "../../context/contact-context/ContactContext";
import {ItineraryContext} from "../../context/itinerary-context/ItineraryContext";
import {ItineraryContextActionTypes} from "../../context/itinerary-context/ItineraryContextModels";
//import { ItineraryEditPanel } from "./itinerary-edit-panel/ItineraryEditPanel";
import './ItineraryPage.css';
import {ProfileContext} from "../../context/profile-context/ProfileContext";
import {TripContext} from "../../context/trip-context/TripContext";
import {Buffer} from "buffer";

export const ItineraryPage = () => {
    const { itineraryState, itineraryDispatch } = useContext(ItineraryContext.getContext());
    console.log("Itinerary State:");
    console.log(JSON.stringify(itineraryState.itineraryList));
    const { tripState } = useContext(TripContext.getContext());
    const { profileState } = useContext(ProfileContext.getContext());
    const { contactState } = useContext(ContactContext.getContext());
    console.log("trip context:");
    console.log(tripState.tripList);
    console.log("profile state:");
    console.log(profileState.profile);
    console.log(contactState.contacts);
    const profileId = profileState.profile[0].profileId;
    const tripList = tripState.tripList.filter((e) => e.profileID == profileId);
    const contactList = contactState.contacts.filter((e) => e.creatorId == profileId);
    const [editPanelTitle, setEditPanelTitle] = useState('Edit itinerary');
    const [showEditPanel, setShowEditPanel] = useState(false);
    const [formDisplay, setFormDisplay] = useState(false);
    let formData = <div></div>;
    const [eventDisplay, setEventDisplay] = useState(true);
    const [showAddEvent, setShowAddEvent] = useState(false);
    const [showModifyEvent, setShowModifyEvent] = useState(false);
    let eventData = <div></div>;
    let matchingEvents: ItineraryAPIModel[] = [];
    const [selectedTrip, setSelectedTrip] = useState("");
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [location, setLocation] = useState("");
    const [eventName, setEventName] = useState("");
    const itinPlaceholder: ItineraryAPIModel = {
        endTime: 0,
        eventId: "0",
        eventLocation: "0",
        eventName: "0",
        startTime: 0,
        tripId: "0"
    };
    const [selectedEvent, setSelectedEvent] = useState(itinPlaceholder);
    const [modStartTime, setModStartTime] = useState(new Date());
    const [modEndTime, setModEndTime] = useState(new Date());
    const [modLocation, setModLocation] = useState("");
    const [modEventName, setModEventName] = useState("");


    useEffect(() => {
        if (tripList.length > 0) {
            formData = tripForm(tripList);
            setFormDisplay(true);
        }
    }, []);

    useEffect(() => {
        handleTripSelect(selectedTrip);
    }, [itineraryState.itineraryList]);


    const toggleEditPanel = () => {
        setEditPanelTitle('Add itinerary');
        setShowEditPanel(!showEditPanel);
        setShowAddEvent(true);
    }


    //formats the listing on the initial itinerary page. n
    function generateListing(entry: ItineraryAPIModel) {
        const startTime = new Date(entry.startTime);
        const endTime = new Date(entry.endTime);
        return (
            <div className="event-listing">
                <p><b>Event Name:</b> {entry.eventName}<br/>
                    <div className="event-details">
                        Start Date: {startTime.toString()}<br/>
                    End Date: {endTime.toString()}<br/>
                        Location: {entry.eventLocation}
                    </div>
                </p>
                <button className="itinerary-button" onClick={() => handleModifyEvent(entry)}>Modify Event</button>
                <button className="itinerary-button" onClick={() => handleDeleteEvent(entry.eventId)}>Delete Event</button>
            </div>
        );
    }

    const handleModifyEvent = (entry: ItineraryAPIModel) => {
        modifyEvent();
        setSelectedEvent(entry);
        setShowModifyEvent(true);
    }

    //separates entries by day, allowing for displaying the itinerary events per day rather than one giant list
    function separateByDay(props: ItineraryAPIModel[]) {
        let arr = props;
        let divDays = [];
        while (arr.length !== 0) {
            let currEntry = arr[0];
            let currDay = arr.filter((e) =>
                (new Date(arr[0].startTime).getDate() === new Date(e.startTime).getDate()) &&
                (new Date(arr[0].startTime).getMonth() === new Date(e.startTime).getMonth())
            );
            divDays.push(currDay);
            arr = arr.filter((e) =>
                !((new Date(arr[0].startTime).getDate() === new Date(e.startTime).getDate()) &&
                    (new Date(arr[0].startTime).getMonth() === new Date(e.startTime).getMonth()))
            );
        }
        return divDays;
    }

    function generateOptions(entry: TripAPIModel) {
        return <option value={entry.tripID}>{entry.tripName}</option>
    }

    const tripForm = (props: TripAPIModel[]) => {
        if (props.length === 0) {
            return (
                <p>No trips found. Unable to add events to any trip.</p>
            )
        }
        else {
            return (
                <div className="select-border">
                    <select className="trip-select"
                        onChange={(e) => handleTripSelect(e.target.value)}>
                        <option value="">Select a Trip</option>
                        {props.map(entry => generateOptions(entry))}
                    </select>
                </div>
            );
        }
    }

    //handles trip selection -- could probably be merged with something else since context simplifies it so much but oh well
    const handleTripSelect = (e: string) => {
        if (e !== "" && e != null) {
            console.log("Trip Select: ");
            console.log(itineraryState.itineraryList);
            setSelectedTrip(e);
            console.log("Selected Trip: " + e);
            const match = itineraryState.itineraryList.filter((f) => f.tripId === e);
            matchingEvents = match;
            setEventDisplay(true);
            setEventContent(displayItinerary);
        }
    }


    //handles the display of the trips
    const displayItinerary = () => {
        if (matchingEvents.length === 0) {
            return (
                <div>
                    <p>No events found. Unable to display itinerary.</p>
                </div>
            )
        }
        else {
            console.log(matchingEvents);
            var currentTime = new Date();
            var currentEvents = matchingEvents.filter((ev) => new Date(ev.startTime) <= currentTime && new Date(ev.endTime) >= currentTime);
            var sortedCurrent = currentEvents.sort((a, b) => {
                return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            });
            console.log("Current Events:");
            console.log(sortedCurrent);
            var upcomingEvents = matchingEvents.filter((ev) => new Date(ev.startTime) > currentTime && new Date(ev.endTime) > currentTime);
            var sortedUpcoming = upcomingEvents.sort((a, b) => {
                return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            });
            console.log("Upcoming Events:");
            console.log(sortedUpcoming);
            var priorEvents = matchingEvents.filter((ev) => new Date(ev.startTime) < currentTime && new Date(ev.endTime) < currentTime);
            var sortedPrior = priorEvents.sort((a, b) => {
                return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            });
            console.log("Prior Events:");
            console.log(sortedPrior);
            var divCurrent = separateByDay(sortedCurrent);
            var divUpcoming = separateByDay(sortedUpcoming);
            var divPrior = separateByDay(sortedPrior);
            console.log("Div Current");
            console.log(divCurrent);
            return (
                <div>
                    <div className="time-header">Current Events:</div>
                    <div className="current-events">
                        {divCurrent.map(entry =>
                            <div>
                                <div className="date-listing">
                                    <b>{new Date(entry[0].startTime).getMonth() + 1}/
                                    {new Date(entry[0].startTime).getDate()}/
                                    {new Date(entry[0].startTime).getFullYear()}</b>
                                </div>
                                {entry.map(ev => generateListing(ev))}
                            </div>
                        )}
                    </div>
                    <div className="time-header">Upcoming Events:</div>
                    <div id="upcomingEvents">
                        {divUpcoming.map(entry =>
                            <div>
                                <div className="date-listing">
                                    <b>{new Date(entry[0].startTime).getMonth() + 1}/
                                    {new Date(entry[0].startTime).getDate()}/
                                    {new Date(entry[0].startTime).getFullYear()}</b>
                                </div>
                                {entry.map(ev => generateListing(ev))}
                            </div>
                        )}
                    </div>
                    <div className="time-header">Prior Events:</div>
                    <div id="priorEvents">
                        {divPrior.map(entry =>
                            <div>
                                <div className="date-listing">
                                    <b>{new Date(entry[0].startTime).getMonth() + 1}/
                                    {new Date(entry[0].startTime).getDate()}/
                                    {new Date(entry[0].startTime).getFullYear()}</b>
                                </div>
                                {entry.map(ev => generateListing(ev))}
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    }
    const [eventContent, setEventContent] = useState(displayItinerary);

    //future sprints probably want to make something more complex but for now this just converts string to hex
    const generateEventId = () => {
        return Buffer.from(eventName, 'utf-8').toString('hex');
    }

    const generateModEventId = () => {
        return Buffer.from(modEventName, 'utf-8').toString('hex');
    }


    const handleSubmit = () => {
        if (startTime != null && endTime != null && eventName !== "" && location !== "") {
            const itinerary: ItineraryAPIModel = {
                endTime: endTime.getTime(),
                eventId: generateEventId(),
                eventLocation: location,
                eventName: eventName,
                startTime: startTime.getTime(),
                tripId: selectedTrip
            };
            itineraryDispatch({
                type: ItineraryContextActionTypes.ADD_EVENT,
                payload: itinerary
            });
            handleTripSelect(selectedTrip);
            setShowAddEvent(false);
        }
    }

    const handleDeleteEvent = (eId: string) => {
        itineraryDispatch({
            type: ItineraryContextActionTypes.DELETE_EVENT,
            payload: eId
        });
        handleTripSelect(selectedTrip);
        setShowAddEvent(false);
        setShowModifyEvent(false);
    }


    const addEvent = (tripId: string) => {
        return (
            <Fragment>
                <FormContainer onSubmit={handleSubmit}>
                    <label>Event Name:
                        <FormText
                            id="eventName"
                            initialValue={eventName}
                            onChange={setEventName}
                        />
                    </label>
                    <label>Start Date:
                        <FormDate
                            id="startTimePicker"
                            initialValue={new Date().toString()}
                            onChange={(e) => setStartTime(e)}
                        /></label>
                    <label>End Date:
                        <FormDate
                            id="endTimePicker"
                            initialValue={new Date().toString()}
                            onChange={(e) => setEndTime(e)}
                        /></label>
                    <label>
                        Location:
                        <FormText
                            id="eventLocation"
                            initialValue={location}
                            onChange={e => setLocation(e)}
                        />
                    </label>
                    <br/>
                    <FormSubmit/>
                </FormContainer>
            </Fragment>
        )
    }

    const handleModifySubmit = () => {

        if (modStartTime != null && modEndTime != null && modEventName !== "" && modLocation !== "") {
            console.log("Submitting");
            const itinerary: ItineraryAPIModel = {
                endTime: modEndTime.getTime(),
                eventId: selectedEvent.eventId,
                eventLocation: modLocation,
                eventName: modEventName,
                startTime: modStartTime.getTime(),
                tripId: selectedEvent.tripId
            };
            itineraryDispatch({
                type: ItineraryContextActionTypes.REPLACE_EVENT,
                payload: itinerary
            });
            handleTripSelect(selectedTrip);
            setShowModifyEvent(false);
        }
    }


    const modifyEvent = () => {
        return (
            <Fragment>
                <FormContainer onSubmit={handleModifySubmit}>
                    <label>Event Name:
                        <FormText
                            id="eventName"
                            initialValue={selectedEvent.eventName}
                            onChange={setModEventName}
                        />
                    </label>
                    <label>Start Date:
                        <FormDate
                            id="startTimePicker"
                            initialValue={new Date(selectedEvent.startTime).toString()}
                            onChange={(e) => setModStartTime(e)}
                        /></label>
                    <label>End Date:
                        <FormDate
                            id="endTimePicker"
                            initialValue={new Date(selectedEvent.endTime).toString()}
                            onChange={(e) => setModEndTime(e)}
                        /></label>
                    <label>
                        Location:
                        <FormText
                            id="eventLocation"
                            initialValue={selectedEvent.eventLocation}
                            onChange={e => setModLocation(e)}
                        />
                    </label>
                    <br/>
                    <FormSubmit/>
                </FormContainer>
            </Fragment>
        )
    }
    return (
        <Fragment>
            <PageContent>
                <div className='itinerary-page-title-row'>
                    <h1>Itinerary</h1>
                </div>
                {tripForm(tripList)}
                {eventDisplay && eventContent}
                {selectedTrip.length > 0 && <Button className='itinerary-page-add' onClick={toggleEditPanel}>
                    <Icon alt='Add itinerary' src={plusIcon} />
                </Button>}
                {showAddEvent && addEvent(selectedTrip)}
                {showModifyEvent && modifyEvent()}
            </PageContent>
            <Footer />
        </Fragment>
    )
}
