import { Button, PageContent, Icon } from "@jam-dev/starlight-react";
import { Fragment, useContext, useRef, useState } from "react";

import Footer from "../../Components/common/footer/Footer";
import closeIcon from "../../assets/icons/close.svg";
import ReviewList from "../../Components/common/review/ReviewList";
import MapComponent from "../../Components/map/MapComponent";
import "./LocationReviewPage.css";
import {
  ReviewAPIModel,
  ReviewVotesAPIModel,
} from "@safe-traveler/safe-traveler-data-models";
import {
  ReviewPreviewList,
  ReviewPreviewProps,
} from "@safe-traveler/react-component-lib";
import { getDate, getTime } from "../../Components/helpers/DateFormatter";
import { ReviewContext } from "../../context/review-context/ReviewContext";
import { Link } from "react-router-dom";
import plusIcon from "../../assets/icons/plus.svg";
import { ReviewEditPanel } from "./review-edit-panel/ReviewEditPanel";
import { ReviewContextActionTypes } from "../../context/review-context/ReviewContextModels";
import { newUUID } from "@safe-traveler/safe-traveler-data-models/dist/type/UUID";

export default function LocationReviewPage() {
  const mapComponentRef = useRef();
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const { reviewState, reviewDispatch } = useContext(
    ReviewContext.getContext()
  );
  const [editPanelTitle, setEditPanelTitle] = useState("Edit review");
  const [showEditPanel, setShowEditPanel] = useState(false);

  const getReviewPreviewListProps = (
    reviewList: ReviewAPIModel[],
    reviewVotesList: ReviewVotesAPIModel[]
  ) => {
    const reviewPreviewListProps: ReviewPreviewProps[] = [];

    reviewList.forEach((reviewAPIModel) => {
      const dateTimePosted = new Date(reviewAPIModel.datePosted);

      let v = 0;
      reviewVotesList.forEach((reviewVotesAPIModel) => {
        if (reviewAPIModel.reviewId === reviewVotesAPIModel.reviewId) {
          v = reviewVotesAPIModel.vote;
        }
      });

      reviewPreviewListProps.push({
        date: getDate(dateTimePosted),
        title: reviewAPIModel.title,
        location: reviewAPIModel.location,
        time: getTime(dateTimePosted),
        rating: Number(reviewAPIModel.rating),
        body: reviewAPIModel.body,
        upvotes: Number(reviewAPIModel.upvotes),
        downvotes: reviewAPIModel.downvotes,
        profileId: "c1",
        reviewId: reviewAPIModel.reviewId,
        reviewVote: v,
      });
    });

    return reviewPreviewListProps;
  };

  const mapComponentProps = {
    ref: mapComponentRef,
    showCheckInLocations: false,
  };

  const toggleEditPanel = () => {
    setEditPanelTitle("Add review");
    setShowEditPanel(!showEditPanel);
  };

  const handleVote = (reviewId: string, profileId: string, vote: number) => {};

  return (
    <Fragment>
      <ReviewEditPanel
        onClose={toggleEditPanel}
        open={showEditPanel}
        title={<h1>{editPanelTitle}</h1>}
      />
      <PageContent>
        <h1>Location Reviews</h1>
        <div className="location-review-page-map">
          <MapComponent {...mapComponentProps} />
        </div>
        <div>
          <br />
          <br />
          <br />

          <div className="location-review-page-buttons">
            <Button onClick={() => setDisplayOverlay(!displayOverlay)}>
              Reviews
            </Button>

            <Button className="review-page-add" onClick={toggleEditPanel}>
              <Icon alt="Add review" src={plusIcon} />
            </Button>
          </div>

          <div className={`Overlay ${displayOverlay ? "Show" : ""}`}>
            <h3>Reviews</h3>
            <button
              className="Close"
              onClick={() => setDisplayOverlay(!displayOverlay)}
            >
              <img className="svg" src={closeIcon} width="24px" alt="close" />
            </button>

            <ReviewPreviewList
              reviews={getReviewPreviewListProps(
                reviewState.reviewList,
                reviewState.reviewVotesList
              )}
              onUserVote={(reviewId, profileId, vote) => {
                console.log(reviewId + " " + profileId + " " + vote); // need to replace with logic to update in database
                reviewDispatch({
                  type: ReviewContextActionTypes.ADD_REVIEW_VOTE,
                  payload: {
                    profileId: profileId,
                    reviewId: reviewId,
                    vote: vote,
                  },
                });
              }}
            />
          </div>
        </div>
      </PageContent>
      <Footer />
    </Fragment>
  );
}
