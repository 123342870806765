import closeIcon from '../../../assets/icons/close.svg';
import mapMarkerIcon from "../../../assets/icons/map-marker.svg";
import TagList from "../tag-list/TagList";
import "./Trip.css";

interface TripProps {
  contactNames: string[];
  endDateTime: string;
  onDelete?: () => any;
  startDateTime: string;
  tripCity: string;
  tripName: string;
}

const Trip = ({contactNames, endDateTime, onDelete, startDateTime, tripCity, tripName}: TripProps) => {
  return (
    <div className="trip">
      <div className="trip-decoration">
        <img className="svg" src={mapMarkerIcon} alt="Map marker" width="32px" />
      </div>
      <div className="trip-details">
        <p className="trip-name">{tripName}</p>
        <p className="trip-location-date">{tripCity} from {startDateTime} to {endDateTime}</p>
        <TagList
          tags={contactNames}
        />
      </div>
      {
        onDelete ? 
        <img className='svg' src={closeIcon} alt={'Delete trip'} width="24px" onClick={onDelete} /> :
        null
      }
    </div>
  );
}

export default Trip;
