import { Banner, Button, Icon, PageContent } from "@jam-dev/starlight-react";
import { Fragment, useContext } from "react";
import Footer from "../../../Components/common/footer/Footer";
import friendsIllustration from '../../../assets/illustration/undraw_coffee_with_friends.svg';
import { ReviewAPIModel } from "@safe-traveler/safe-traveler-data-models";
import { ReviewPreviewDelete } from "@safe-traveler/react-component-lib";
import { getDate, getTime } from "../../../Components/helpers/DateFormatter";
import { ReviewContext } from "../../../context/review-context/ReviewContext";
import { ProfileContext } from "../../../context/profile-context/ProfileContext";
import { ReviewContextActionTypes } from "../../../context/review-context/ReviewContextModels";
import './MyReviewPage.css';
import { useNavigate } from "react-router-dom";

export default function ReviewPage() {
  let navigate = useNavigate();

  const { reviewState, reviewDispatch } = useContext(
    ReviewContext.getContext()
  );
  const { profileState, profileDispatch } = useContext(
    ProfileContext.getContext()
  );

  const handleDelete = (reviewId: string) => {
    reviewDispatch({
      type: ReviewContextActionTypes.DELETE_REVIEW,
      payload: reviewId
    })
  }

  const handleModify = (reviewId: string) => {
    navigate(`/reviews/${reviewId}`);
  }

  const display = () => {
    const reviewList = reviewState.reviewList;
    const reviewElements: JSX.Element[] = [];
    const profileId = profileState.profile[0].profileId;

    reviewList.forEach((reviewAPIModel: ReviewAPIModel) => {
      const dateTimePosted = new Date(reviewAPIModel.datePosted);
      if (reviewAPIModel.creatorId == profileId) {
        reviewElements.push(
          <ReviewPreviewDelete 
            date={getDate(dateTimePosted)} 
            title={reviewAPIModel.title} 
            location={reviewAPIModel.location} 
            time={getTime(dateTimePosted)} 
            rating={Number(reviewAPIModel.rating)} 
            body={reviewAPIModel.body} 
            profileId={profileId} 
            reviewId={reviewAPIModel.reviewId}    
            onDelete={() => {handleDelete(reviewAPIModel.reviewId)}} 
            onModify={() => {handleModify(reviewAPIModel.reviewId)}}
          />
        )
      }
    });
    return  reviewElements;
  }

  return (
    <Fragment>
      <PageContent>
        <h1>My Reviews</h1>
        <img alt='Reviews art' className='reviews-page-illustration' src={friendsIllustration} />
        
        {
            reviewState.reviewList.length > 0 ? display() : 
            <Banner className='reviews-page-no-review-message'>
            <p>No reviews added. Add reviews using the button above.</p>
          </Banner>
        }

      </PageContent>
      <Footer />
    </Fragment>
  )
}