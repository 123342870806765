import { Button, Icon, PageContent } from "@jam-dev/starlight-react";
import { Fragment, useContext, useState } from "react";
import pencilIcon from '../../assets/icons/pencil.svg';
import workingIllustration from '../../assets/illustration/undraw_working_remotely.svg';
import Footer from "../../Components/common/footer/Footer";
import { ProfileContext } from "../../context/profile-context/ProfileContext";
import { ProfileContextActionTypes } from '../../context/profile-context/ProfileContextModels';
import { ProfileEditPanel } from "./profile-edit-panel/ProfileEditPanel";
import './ProfilePage.css';

export const ProfilePage = () => {
  const [editPanelTitle, setEditPanelTitle] = useState('Edit profile');
  const [showEditPanel, setShowEditPanel] = useState(false);
  const {profileState, profileDispatch} = useContext(ProfileContext.getContext());

  if (profileState.profile.length == 0) {
    return <div></div>;
  }

  console.log(JSON.stringify(profileState))
  const profileId = profileState.profile[0]?.profileId;


  const toggleEditPanel = () => {
    setEditPanelTitle('Edit Profile');
    setShowEditPanel(!showEditPanel);
  }

  const handleDelete = () => {
    if (!profileId) return;
    profileDispatch({
      type: ProfileContextActionTypes.DELETE_PROFILE,
      payload: profileId,
    })
  }
  
  return (
    <Fragment>
      <ProfileEditPanel
        onClose={toggleEditPanel}
        open={showEditPanel}
        title={<h1>{editPanelTitle}</h1>}
      />
      <PageContent>
        <h1>Profile</h1>
        <img alt='Profile art' className='profile-page-illustration' src={workingIllustration} />
        <h2>{profileState.profile[0].firstName} {profileState.profile[0].lastName}</h2>
        <h3>{profileState.profile[0].email}</h3>
        <h3>{profileState.profile[0].phoneNumber}</h3>
        <Button className='profile-page-add' onClick={toggleEditPanel}>
          <Icon alt='Edit profile' src={pencilIcon} />
        </Button>
        <Button  className='profile-page-add' onClick={handleDelete}>Delete Profile</Button>
      </PageContent>
      <Footer />
    </Fragment>
  )
}
