import { PageContent } from "@jam-dev/starlight-react";
import { TripAPIModel } from "@safe-traveler/safe-traveler-data-models";
import { Fragment, useContext, useState, useRef } from "react";
import CheckInButtons from "../../Components/checkin-buttons/CheckInButtons";
import MapComponent from "../../Components/map/MapComponent";
import { TripContext } from "../../context/trip-context/TripContext";
import { Button } from "@jam-dev/starlight-react";
import { SMSAPI } from "../../api/SMSAPI";
import { ContactContext } from "../../context/contact-context/ContactContext";
import { ProfileContext } from "../../context/profile-context/ProfileContext";
import { TripContextActionTypes } from "../../context/trip-context/TripContextModels";
import "./CurrentTripPage.css";

let checkIns = [
  { lat: 40.42631496422626, lng: -86.9241860412414 },

  { lat: 40.42976669903312, lng: -86.91978721648304 },

  { lat: 40.42197791810427, lng: -86.91676423559183 },

  { lat: 40.422654993919764, lng: -86.9237140074485 },

  { lat: 40.4233090605779, lng: -86.9111287170466 },
];

export const CurrentTripPage = () => {
  const { contactState, contactDispatch } = useContext(
    ContactContext.getContext()
  );
  const { profileState, profileDispatch } = useContext(
    ProfileContext.getContext()
  );
  const { tripState, tripDispatch } = useContext(TripContext.getContext());
  const KEY = "secret key 123";
  var CryptoJS = require("crypto-js");
  const baseContactsAccessURL =
    "https://s236sqpkpk.execute-api.us-east-1.amazonaws.com/prod/v1/share-trip";

  var trip: TripAPIModel[] = [];
  const mapComponentRef = useRef<any>();

  const [address, setAddress] = useState<any[]>([]);
  // const [previousCoords, setPreviousCoords] = useState<any[]>([]);

  const getCurrentTrip = () => {
    const trips = tripState.tripList;
    const today = new Date();
    trips.forEach((element) => {
      const start = new Date(element.startTime);
      console.log("Start Date: " + start);
      const end = new Date(element.endTime);
      console.log("End Date: " + end);
      if (start <= today && end >= today) {
        trip.push(element);
        //setPreviousCoords(element.checkinLocations)
      }
    });
    console.log(trip[0].checkinLocations[0]);
    return trip;
  };

  const [checkInLocations, setCheckInLocations] = useState(checkIns);
  // useState([]);

  const mapComponentProps = {
    trip: getCurrentTrip(),
    // ref: mapComponentRef,
    showCheckInLocations: true,
    checkInLocations: checkInLocations,
    onCheckInLocationChange: (latitude: any, longitude: any) => {
      // make sure to update this to call updateAddress}
      updateAddress(latitude, longitude);
    },
  };

  const updateAddress = (latitude: any, longitude: any) => {
    if (
      mapComponentRef.current != null &&
      mapComponentRef.current.geocodeLatLng(latitude, longitude) != null
    ) {
      setAddress(mapComponentRef.current.geocodeLatLng(latitude, longitude));
      console.log(address);
    }
  };

  const generateURL = (
    profileId: string,
    tripId: string,
    contactId: string
  ) => {
    // If we want to make our encryption {trip id} + {contact_id} or something??
    var shareTripId = profileId + "-" + tripId + "-" + contactId;
    var ciphertext = CryptoJS.AES.encrypt(shareTripId, KEY);
    var replaced = ciphertext
      .toString()
      .replace("+", "xMl3Jk")
      .replace("/", "Por21Ld")
      .replace("=", "Ml32");
    var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), KEY);
    var plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return "/shareTrip/" + replaced.toString();
  };

  const sendUrlAndPinToDB = async (
    profileId: any,
    tripId: any,
    contactId: any,
    generatedPin: { toString: () => any },
    generatedUrl: any
  ) => {
    var partitionKey = `${profileId}-${tripId}-${contactId}`;
    console.log(contactId);
    console.log(
      JSON.stringify({
        profileIdTripIdContactId: partitionKey,
        contactId: contactId,
        pin: generatedPin,
        tripId: tripId,
        url: generatedUrl,
      })
    );
    try {
      const response = await fetch(baseContactsAccessURL, {
        method: "PUT",
        body: JSON.stringify({
          profileIdTripIdContactId: partitionKey,
          contactId: contactId,
          pin: generatedPin.toString(),
          tripId: tripId,
          url: generatedUrl,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Posted URL and PIN successfully.");
      //console.log(response.data);
    } catch (e) {
      console.log("POST ERROR!");
      console.log(e);
    }
  };

  const sendNotification = () => {
    const contacts = JSON.parse(JSON.stringify(contactState.contacts));
    const profile = JSON.parse(JSON.stringify(profileState));
    //console.log(contacts)
    var profileId = profile.profile[0].profileId;
    var firstName = profile.profile[0].firstName;
    var lastName = profile.profile[0].lastName;

    //console.log(profileId, firstName, lastName)

    contacts.forEach(async (element: { phoneNumber: any; contactId: any }) => {
      var generatedPin = Math.floor(100000 + Math.random() * 900000);
      var phoneNumber = element.phoneNumber;
      var contactId = element.contactId;
      console.log("Phone Number: " + phoneNumber);
      var url = generateURL(profileId, contactId, trip[0].tripID);
      console.log(phoneNumber);

      var text =
        `${firstName} ${lastName} put you as an emergency contact for their upcoming trip! ` +
        `Use pin ${generatedPin} at https://www.safetraveler.me${url} to verify your identity when you get a link from us.`;

      console.log(trip[0].tripID);
      console.log(generatedPin, url);

      const data = {
        message: text,
        toPhoneNumber: phoneNumber,
        fromPhoneNumber: "+14246552880",
      };
      console.log("102");
      const response = await SMSAPI.postSendText(
        undefined,
        JSON.stringify(data)
      );
      console.log(response);
      sendUrlAndPinToDB(
        profileId,
        trip[0].tripID,
        contactId,
        generatedPin,
        url
      );
    });
  };

  const checkInButtonClicked = () => {
    console.log("Clicked on Check In button");

    if (mapComponentRef && mapComponentRef.current) {
      const userLocation = mapComponentRef.current.getCurrentLocation();
      let lastCheckInDateTime = Date.now() + "";
      if (userLocation.lat == null || userLocation.lng == null) {
        alert("Please enable your location to check-in");
      } else {
        var str = userLocation.lat + "%" + userLocation.lng;
        var previous = trip[0].checkinLocations;
        console.log("Previous: " + previous);
      }
    }

    sendNotification();
  };

  const sendSOS = async () => {
    const contacts = JSON.parse(JSON.stringify(contactState.contacts));
    const profile = JSON.parse(JSON.stringify(profileState));
    //console.log(contacts)
    var profileId = profile.profile[0].profileId;
    var firstName = profile.profile[0].firstName;
    var lastName = profile.profile[0].lastName;
    var text =
    ` ${firstName} ${lastName} pressed the SOS button on safetraveler.
      Last known location of ${firstName} ${lastName}: West Lafayette
      Expected current location from their itinerary: Indianapolis
      Last time they checked in on the site: 10:22 am 12/08/2022
      Notes for the trip: I'm backpacking in Indiana`;


    const data = {
      message: text,
      toPhoneNumber: "8123603318",
      fromPhoneNumber: "+14246552880",
    };
    console.log("102");
    const response = await SMSAPI.postSendText(undefined, JSON.stringify(data));
    console.log(response);
  };

  return (
    <Fragment>
      <PageContent>
        <h1>Current Trip</h1>
        {getCurrentTrip().length == 0 ? (
          <p>No Current Trip found</p>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h2>{trip[0].tripName}</h2>
            <div className="location-review-page-map">
              <MapComponent ref={mapComponentRef} {...mapComponentProps} />
            </div>
            <br />
            <br />
            <br />
            <div className="current-trip-page-buttons">
              <Button onClick={checkInButtonClicked}>Check In</Button>
              <Button onClick={sendSOS}>SOS</Button>
            </div>
          </div>
        )}
      </PageContent>
    </Fragment>
  );
};
