import { Dispatch } from 'react';
import { ContactAPIModel } from '@safe-traveler/safe-traveler-data-models';

/**
 * Core types required for contact context to function
 */
// state and dispatch pointers that are exposed by the contact context.
export interface ContactContextValue {
  contactState: ContactContextState;
  contactDispatch: ContactContextDispatch;
}

// Contains the data of the contact context.
export interface ContactContextState {
  contacts: ContactAPIModel[]
}

// Type of reducer dispatch function for contact context.
export type ContactContextDispatch = Dispatch<ContactContextReducerAction>;

// Input for contact context dispatch funcntion.
export interface ContactContextReducerAction {
  type: ContactContextActionTypes;
  payload:
      ContactContextAddContactPayload |
      ContactContextAddContactsPayload |
      ContactContextDeleteContactPayload |
      ContactContextDeleteContactsPayload |
      ContactContextEditContactsPayload,
}

export enum ContactContextActionTypes {
  ADD_CONTACT,
  ADD_CONTACTS,
  DELETE_CONTACT,
  DELETE_CONTACTS,
  EDIT_CONTACT,
}

export interface ContactContextAddContactPayload {
  contact: ContactAPIModel
}

export interface ContactContextAddContactsPayload {
  contacts: ContactAPIModel[]
}

export interface ContactContextDeleteContactPayload {
  contact: string
}

export interface ContactContextDeleteContactsPayload {
  contacts: string[]
}
export interface ContactContextEditContactsPayload {
  contact: ContactAPIModel
}