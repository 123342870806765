import { PageContent } from "@jam-dev/starlight-react";
import { FormContainer, FormRow, FormSubmit, FormText, FormTextArea } from "@safe-traveler/react-component-lib";
import { FormEvent, Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormTextInput from "../../Components/common/form-text-input/FormTextInput";
import TagList from "../../Components/common/tag-list/TagList";
import TripList from "../../Components/common/trip-list/TripList";
import { ContactContext } from "../../context/contact-context/ContactContext";
import { NotificationContext } from "../../context/notification-context/NotificationContext";
import { NotificationContextActionTypes } from "../../context/notification-context/NotificationContextModels";
import { TripContext } from "../../context/trip-context/TripContext";
import "./DevPage.css";

const tags: string[] = [
  'Jim Bob',
  'Purdue Pete',
  'Joe Mama',
  'Ligma',
  'Mike Litoris',
  'Hugh Jass',
  'Hugh G. Rection',
  'Mike Hawk',
  'Mike Oxlong'
];

const DevPage = () => {
  const { contactState } = useContext(ContactContext.getContext());
  const { notificationDispatch } = useContext(NotificationContext.getContext());
  const { tripState } = useContext(TripContext.getContext());

  const [ notificationValue, setNotificationValue ] = useState('');
  const [ notificationTTL, setNotificationTTL ] = useState('');

  const addNotification = (e: FormEvent, value: string, ttlString: string) => {
    e.preventDefault();

    let ttl: number | undefined = parseInt(ttlString);
    // if (ttl === NaN) {
    //   ttl = undefined;
    // }

    notificationDispatch({
      type: NotificationContextActionTypes.ADD_NOTIFICATION,
      payload: { value, ttl }
    });
  }
  
  let navigate = useNavigate();
  const editReview = () => {
    var reviewId = 5;
    navigate(`/reviews/${reviewId}`);
  }

  const contacts = contactState.contacts.map(e => `${e.firstName} ${e.lastName}`);

  return (
    <Fragment>
      <PageContent>
        <h1>Dev Page</h1>
        <p>This page is used for displaying data, manually testing functionality, and visualizing features.</p>
        <h2>Notification context</h2>
        <form className="dev-notification-form" onSubmit={
          e => addNotification(e, notificationValue, notificationTTL)
        }>
          <FormTextInput id="notification-text" name="Notification text" placeholder="Hello world!" onChange={e => {
            if (e.target.value) {
              setNotificationValue(e.target.value);
            }
          }} />
          <FormTextInput id="notification-ttl" name="TTL" placeholder="3" onChange={e => {
            if (e.target.value) {
              setNotificationTTL(e.target.value);
            }
          }} />
          <FormSubmit value="Add notification" />
        </form>
        <hr />
        <h2>{'<TagList />'}</h2>
        <TagList tags={contacts} />
        <hr />
        <h2>{'<TripList />'}</h2>
        <p>Using trip history as example</p>
        <TripList isHistory />
        <hr />
        <h2>{'Forms'}</h2>
        <FormContainer onSubmit={() => {}}>
          <FormRow>
            <FormText
              id='dev-page-text-input'
              label='Text input'
              onChange={() => {}}
              placeholder='Placeholder'
            />
            <FormText
              id='dev-page-text-input-2'
              label='Text input 2'
              onChange={() => {}}
              placeholder='Placeholder'
            />
          </FormRow>
          <FormRow>
            <FormTextArea
              id='dev-page-textarea-input'
              label='Text area'
              onChange={() => {}}
              placeholder='Placeholder'
            />
          </FormRow>
          <FormRow>
            <FormSubmit value='Confirm' />
          </FormRow>
        </FormContainer>
        <hr />
        <h2>Example section</h2>
        <p>Implement more dev features here</p>
        <button onClick={editReview}>Edit Review</button>
      </PageContent>
    </Fragment>
  );
}

export default DevPage;
