import { Context, useReducer } from "react";
import { NotificationContext } from "./NotificationContext"
import { NotificationContextValue } from "./NotificationContextModels";

const ReactContext: Context<NotificationContextValue> = NotificationContext.getContext();

/**
 * Notification context provider component necessary for holding the reducer and
 * providing context to descendent React components.
 * 
 * All descendent components can access the values within Context.Provider's
 * value attribute using the following code:
 * const { notificationState, notificationDispatch } =
 *     React.useContext(NotificationContext.getContext());
 */
const NotificationContextProvider = ({children}: any) => {
  const [notificationState, notificationDispatch] = useReducer(NotificationContext.reducer, NotificationContext.getInitialState());

  return (
    <ReactContext.Provider value={{notificationState, notificationDispatch}}>
      {children}
    </ReactContext.Provider>
  );
}

export default NotificationContextProvider;
