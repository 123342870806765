import { Context, useEffect, useReducer } from "react";
import { TripHistoryAPI } from "../../api/TripHistoryAPI";
import { TripsAPI } from "../../api/TripsAPI";
import { TripContext } from "./TripContext";
import { TripContextActionTypes, TripContextValue } from "./TripContextModels";

const ReactContext: Context<TripContextValue> = TripContext.getContext();

/**
 * Trip context provider component necessary for holding the reducer and
 * providing context to descendent React components.
 * 
 * All descendent components can access the values within Context.Provider's
 * value attribute using the following code:
 * const { tripState, tripDispatch } =
 *     React.useContext(TripContext.getContext());
 */
const TripContextProvider = ({children}: any) => {
  const [tripState, tripDispatch] = useReducer(TripContext.reducer, TripContext.getInitialState());

  useEffect(() => {
    const getTrips = async () => {
      const response = await TripsAPI.getAllTrips();

      tripDispatch({
        type: TripContextActionTypes.SYNC_TRIPS,
        payload: response,
      });
    }

    const getTripsHistory = async () => {
      const response = await TripHistoryAPI.getAllTripsHistory();

      tripDispatch({
        type: TripContextActionTypes.SYNC_TRIPS_HISTORY,
        payload: response,
      });
    }

    getTrips();
    getTripsHistory();
  }, []);

  return (
    <ReactContext.Provider value={{tripState, tripDispatch}}>
      {children}
    </ReactContext.Provider>
  );
}

export default TripContextProvider;
