// import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import PinVerification from "./pin/PinVerification";
import { ContactContext } from "../../context/contact-context/ContactContext";
import { PageContent } from "@jam-dev/starlight-react";

export default function ShareTripPage() {
  // id is the encripted id of a row of shareTrip table
  const { url } = useParams();
  const { contactState, contactDispatch } = useContext(
    ContactContext.getContext()
  );

  // decrypting
  const KEY = "secret key 123";
  var CryptoJS = require("crypto-js");
  var replaced = url
    ? url
        .toString()
        .replaceAll("xMl3Jk", "+")
        .replaceAll("Por21Ld", "/")
        .replaceAll("Ml32", "=")
    : "";
  var bytes = CryptoJS.AES.decrypt(replaced, KEY);
  var decodedId = bytes.toString(CryptoJS.enc.Utf8);
  var arr = decodedId.split("/");
  var profileId = arr[0];
  var tripId = "21f72098-d4a3-4e2c-949d-e743a66c1f41";
  var contactId = arr[2];
  console.log(profileId, tripId, contactId);
  var contactData = contactState.contacts.find((contact) => {
    return contact.contactId === contactId;
  });
  var blocked = false;
  var expectedPin = "123456";

  return (
    <PageContent>
      <h1>Shared Trip</h1>
      {!blocked && (
        <PinVerification
          expectedPin={expectedPin}
          profileId={profileId}
          tripId={tripId}
        />
      )}
      {blocked && <p>Cannot access this page.</p>}
    </PageContent>
  );
}
