import { ChangeEventHandler, useState } from "react";
import "./FormTextInput.css";

interface FormTextInputProps {
  id: string;
  name: string;
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  initialValue?: string;
}

const FormTextInput = ({id, name, placeholder, onChange, initialValue}: FormTextInputProps) => {
  const [value, setValue] = useState(initialValue || '');

  return (
    <div className="form-text-input">
      <label htmlFor={id}>{name}</label>
      <input
        type="text"
        id={id}
        placeholder={placeholder ? placeholder : undefined}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e);
        }}
        value={value}
      />
    </div>
  );
}

export default FormTextInput;
