import { Context, createContext } from "react";
import { ProfileAPI } from "../../api/ProfileAPI";
import {
  ProfileContextActionTypes,
  ProfileContextAddProfileAction,
  ProfileContextEditProfileAction,
  ProfileContextDeleteProfileAction,
  ProfileContextState,
  ProfileContextValue
} from "./ProfileContextModels";

const initialState: ProfileContextState = {
  profile: []
};

/**
 * Facade class for profile context. This class only contains static methods for
 * accessing and modifying the profile context.
 * 
 * When adding complex functionality to the reducer, create additional static
 * helper methods below and call them within reducer().
 */
export class ProfileContext {

  private static Context: Context<ProfileContextValue> = createContext({} as ProfileContextValue);
  private static initialState = initialState;

  public static getContext(): Context<ProfileContextValue> {
    return ProfileContext.Context;
  }

  public static getInitialState(): ProfileContextState {
    return ProfileContext.initialState;
  }

  /**
   * Takes the old state and an action as argument, and returns a new state
   * with the same shape as the old state. Called when a component uses the
   * profileDispatch() method declared in ProfileContextProvider.
   * 
   * IMPORTANT! The new state must not be the same object reference as the
   * previous state, hence why we create a new state object with JSON parse and
   * JSON stringify.
   * 
   * The action object is an arbitrary object that contains two fields:
   * type: an identifier for the action that should be performed, e.g. update
   * array, remove from array, etc.
   * payload: an arbitrary payload containing information for the reducer to
   * use depending on the action type.
   */
  public static reducer(
    prevState: ProfileContextState,
    action:
      ProfileContextAddProfileAction |
      ProfileContextDeleteProfileAction |
      ProfileContextEditProfileAction
  ): ProfileContextState {
    const state: ProfileContextState = JSON.parse(JSON.stringify(prevState));

    switch (action.type) {
      case ProfileContextActionTypes.ADD_PROFILE:
        state.profile.push(action.payload);
        ProfileAPI.postProfile(action.payload);
        break;
      case ProfileContextActionTypes.EDIT_PROFILE:
        state.profile = state.profile.filter(e => e.profileId !== action.payload.profileId);
        state.profile.push(action.payload);
        ProfileAPI.postProfile(action.payload);
        break;
      case ProfileContextActionTypes.DELETE_PROFILE:
        state.profile = state.profile.filter(e => e.profileId !== action.payload);
        ProfileAPI.deleteProfile(action.payload);
        break;
      default:
        throw Error(`ProfileContext reducer: unsupported action type ${action}`);
    }

    return state;
  }
}