import React, { useRef } from "react";
import "./PinInput.css";

interface PinInputProps {
  pin: Array<string | undefined>;
  onPinChanged: (pinEntry: string | undefined, index: number) => void;
}

const PinInput = ({ pin, onPinChanged }: PinInputProps) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const changeFocus = (index: number) => {
    const ref = inputRefs.current[index];
    if (ref) {
      ref.focus();
    }
  };

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = event.target.value;
    const num = value.trim().charAt(0);
    // if (isNaN(num) || value.length === 0) {
    //   return;
    // }

    if (num >= "0" && num <= "9") {
      console.log(num);
      onPinChanged(num, index);
      if (index < 5) {
        changeFocus(index + 1);
      }
    }
  };

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const code = event.nativeEvent.code;
    if (code === "Backspace") {
      if (pin[index] === undefined) {
        changeFocus(index - 1);
      } else {
        onPinChanged(undefined, index);
      }
    }
  };

  return (
    <div className="pin-inputs">
      {Array.from({ length: 6 }, (_, index) => (
        <input
          className="pin"
          key={index}
          ref={(e) => {
            if (e) {
              inputRefs.current[index] = e;
            }
          }}
          onChange={(event) => onChange(event, index)}
          onKeyDown={(event) => onKeyDown(event, index)}
          value={pin[index] || ""}
        />
      ))}
    </div>
  );
};

export default PinInput;
