import { Context, useEffect, useReducer } from "react";
import { ContactContext } from "./ContactContext";
import { ContactContextActionTypes, ContactContextValue } from "./ContactContextModels";
import { ContactsAPI } from "../../api/ContactsAPI";

const ReactContext: Context<ContactContextValue> = ContactContext.getContext();

/**
 * Contact context provider component necessary for holding the reducer and
 * providing context to descendent React components.
 * 
 * All descendent components can access the values within Context.Provider's
 * value attribute using the following code:
 * const { contactState, contactDispatch } =
 *     React.useContext(ContactContext.getContext());
 */
const ContactContextProvider = ({children}: any) => {
  const [contactState, contactDispatch] = useReducer(ContactContext.reducer, ContactContext.getInitialState());

  useEffect(() => {
    const getContacts = async () => {
      const response = await ContactsAPI.getAllContacts();
      contactDispatch({
        type: ContactContextActionTypes.ADD_CONTACTS,
        payload: {
          contacts: response
        }
      });
    }

    getContacts();
  }, []);

  return (
    <ReactContext.Provider value={{contactState, contactDispatch}}>
      {children}
    </ReactContext.Provider>
  );
}

export default ContactContextProvider;
