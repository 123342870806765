import { useState } from "react";
import ContactView from "../../../route/contact-view/ContactView";
import PinInput from "./PinInput";
import { Button } from "@jam-dev/starlight-react";

// const expectedPin = "123456";

interface PinVerificationProps {
  expectedPin: string;
  profileId: string;
  tripId: string;
}

const PinVerification = ({
  expectedPin,
  profileId,
  tripId,
}: PinVerificationProps) => {
  const [pin, setPin] = useState<Array<string | undefined>>(new Array(6));
  const [verified, setVerified] = useState(false);

  const onPinChanged = (pinEntry: string | undefined, index: number) => {
    const newPin = [...pin];
    newPin[index] = pinEntry;
    setPin(newPin);
  };

  const validatePin = async () => {
    const actualPin = pin.join("");
    setVerified(actualPin == expectedPin);
  };

  return (
    <div>
      {verified ? (
        <ContactView profileId={profileId} tripId={tripId} />
      ) : (
        <div
          className="pin-verification"
          style={{
            alignItems: "center",
            // justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            Please input the verification pin:
          </h2>
          <br />
          <br />
          <br />
          <PinInput pin={pin} onPinChanged={onPinChanged} />
          <br />
          <br />
          <br />
          <Button
            onClick={validatePin}
            style={{
              borderRadius: "32px",
              height: "48px",
              width: "100%",
            }}
          >
            Verify pin
          </Button>
        </div>
      )}
    </div>
  );
};

export default PinVerification;
