import axios from "axios";
import { Context, useEffect, useReducer } from "react";
import { ItineraryContext } from "./ItineraryContext";
import { ItineraryContextActionTypes, ItineraryContextValue } from "./ItineraryContextModels";
import {ItineraryAPI} from "../../api/ItineraryAPI";

const ReactContext: Context<ItineraryContextValue> = ItineraryContext.getContext();

/**
 * Itinerary context provider component necessary for holding the reducer and
 * providing context to descendent React components.
 *
 * All descendent components can access the values within Context.Provider's
 * value attribute using the following code:
 * const { itineraryState, itineraryDispatch } =
 *     React.useContext(ItineraryContext.getContext());
 */
const ItineraryContextProvider = ({children}: any) => {
    const [itineraryState, itineraryDispatch] = useReducer(ItineraryContext.reducer, ItineraryContext.getInitialState());

    useEffect(() => {

        const getItinerary = async () => {
            const response = await ItineraryAPI.getAllEvents();
            console.log("CONTEXT PROVIDER RESPONSE:");
            console.log(response);

            itineraryDispatch({
                type: ItineraryContextActionTypes.SYNC_EVENTS,
                payload: response,
            });
        }

        getItinerary();
    }, []);

    return (
        <ReactContext.Provider value={{itineraryState, itineraryDispatch}}>
            {children}
        </ReactContext.Provider>
    );
}

export default ItineraryContextProvider;
