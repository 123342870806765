import Tag from "../tag/Tag";
import "./TagList.css";

interface TagListProps {
  tags: string[];
}

const TagList = ({tags}: TagListProps) => {
  const renderTags = (tags: string[]): JSX.Element[] => {
    const tagElements: JSX.Element[] = [];

    tags.forEach(tag => {
      tagElements.push(<Tag key={tag} value={tag} />);
    });

    return tagElements;
  }

  return (
    <div className="tag-list">
      { renderTags(tags) }
    </div>
  );
}

export default TagList;
