import { Banner, Button, Icon, PageContent } from "@jam-dev/starlight-react";
import { TripPreviewList, TripPreviewProps } from "@safe-traveler/react-component-lib";
import { TripAPIModel } from "@safe-traveler/safe-traveler-data-models";
import { Fragment, useContext, useState } from "react";
import contentSaveIcon from '../../assets/icons/content-save.svg';
import mapMarkerIcon from '../../assets/icons/map-marker.svg';
import pencilIcon from '../../assets/icons/pencil.svg';
import plusIcon from '../../assets/icons/plus.svg';
import travelingIllustraction from '../../assets/illustration/undraw_traveling.svg';
import Footer from "../../Components/common/footer/Footer";
import { ContactContext } from "../../context/contact-context/ContactContext";
import { TripContext } from "../../context/trip-context/TripContext";
import { TripContextActionTypes } from "../../context/trip-context/TripContextModels";
import { TripEditPanel } from "./trip-edit-panel/TripEditPanel";
import './TripsPage.css';

export const TripsPage = () => {
  const { tripState, tripDispatch } = useContext(TripContext.getContext());
  const { contactState } = useContext(ContactContext.getContext());
  const [allowEdit, setAllowEdit] = useState(false);
  const [editPanelTitle, setEditPanelTitle] = useState('Edit trip');
  const [showEditPanel, setShowEditPanel] = useState(false);
  const [prefillModel, setPrefillModel] = useState<TripAPIModel | undefined>(undefined);

  const getTripPreviewListProps = (tripList: TripAPIModel[]) => {
    const tripPreviewListProps: TripPreviewProps[] = [];

    tripList.forEach(tripAPIModel => {
      const startTime = new Date(tripAPIModel.startTime);
      const endTime = new Date(tripAPIModel.endTime);

      tripPreviewListProps.push({
        contacts: contactState.contacts.filter(e => tripAPIModel.contacts.includes(e.contactId)).map(e => `${e.firstName} ${e.lastName}`),
        location: tripAPIModel.tripCity,
        onClick: allowEdit ? (tripID: string) => openEditPanelEdit(tripID) : undefined,
        time: `${startTime.toDateString()} ${startTime.getHours()}:00 to ${endTime.toDateString()} ${endTime.getHours()}:00`,
        title: tripAPIModel.tripName,
        tripID: tripAPIModel.tripID,
      });
    });

    return tripPreviewListProps;
  }

  const toggleEditEntries = () => {
    setAllowEdit(!allowEdit);
  }

  const openEditPanelAdd = () => {
    setEditPanelTitle('Add trip');
    setShowEditPanel(true);
  }

  const openEditPanelEdit = (tripID: string) => {
    setEditPanelTitle('Edit trip');
    setShowEditPanel(true);

    const tripAPIModel = tripState.tripList.find(e => e.tripID === tripID);
    if (tripAPIModel) setPrefillModel(tripAPIModel);
  }

  const closeEditPanel = () => {
    setShowEditPanel(false);
    setPrefillModel(undefined);
  }

  const handleDelete = (tripID: string) => {
    tripDispatch({
      type: TripContextActionTypes.DELETE_TRIP,
      payload: tripID,
    })
  }

  return (
    <Fragment>
      <TripEditPanel
        onClose={closeEditPanel}
        open={showEditPanel}
        prefillModel={prefillModel}
        title={<h1>{editPanelTitle}</h1>}
      />
      <PageContent>
        <div className='trips-page-title-row'>
          <h1>Trips</h1>
          {
            tripState.tripList.length > 0 ?
            <Button onClick={toggleEditEntries}>
              <Icon alt='Edit' src={allowEdit ? contentSaveIcon : pencilIcon } />
            </Button> :
            null
          }
        </div>
        <img alt='Trip art' className='trips-page-illustration' src={travelingIllustraction} />
        <Button className='trip-page-add' onClick={openEditPanelAdd}>
          <Icon alt='Add trip' src={plusIcon} />
        </Button>
        {
          tripState.tripList.length > 0 ?
          <TripPreviewList
            onDelete={allowEdit ? handleDelete : undefined}
            trips={getTripPreviewListProps(tripState.tripList)}
          /> :
          <Banner className='trips-page-no-trip-message'>
            <Icon alt='Location icon' src={mapMarkerIcon} />
            <p>No trips added. Add trips using the button above.</p>
          </Banner>
        }
      </PageContent>
      <Footer />
    </Fragment>
  )
}
