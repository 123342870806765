import { APIManager } from './APIManager';

export class SMSAPI extends APIManager {
    // POST /notifications/send-text
    public static async postSendText(queryStringParameters?: {[key: string]: string}, body?: string) {
        const response = await this.postRequest('/v1/notifications/send-text', queryStringParameters, body)
        return response;
    }

    // POST /notifications/send-text-fixed
    public static async postSendTextFixed(queryStringParameters?: {[key: string]: string}, body?: string) {
        const response = await this.postRequest('/v1/notifications/send-text-fixed', queryStringParameters, body)
        return response;
    }
}