import {
  Circle,
  GoogleMap,
  Marker,
  Polyline,
  useLoadScript,
} from "@react-google-maps/api";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import _ from "lodash";
import GeolocationError from "../common/error/GeolocationError";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

let geocoder;

function getCurrentDirection(prevLocation, currLocation) {
  const diffLatitude = currLocation.lat - prevLocation.lat;
  const diffLongitude = currLocation.lng - prevLocation.lng;

  return 90 - convertToDegrees(Math.atan2(diffLatitude, diffLongitude));

  function convertToDegrees(radian) {
    return (radian * 180) / Math.PI;
  }
}

const MapComponent = forwardRef((props, ref) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  let checkInLocations = props.checkInLocations;

  const [userLatitude, setUserLatitude] = useState(null);
  const [userLongitude, setUserLongitude] = useState(null);
  const [userDirection, setUserDirection] = useState(0);
  const [checkInLatitude, setCheckInLatitude] = useState(null); // need to set to other value, like last element in checkinlocations
  const [checkInLongitude, setCheckInLongitude] = useState(null); // need to set to other value, like last element in checkinlocations
  const [radius, setRadius] = useState(15);
  const [showRadius, toggleRadius] = useState(true);
  const [geolocationError, setGeolocationError] = useState(null);

  const mapRef = useRef();

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const findResult = (results, name) => {
    const result = _.find(results, function (obj) {
      return obj.types[0] === name && obj.types[1] === "political";
    });

    return result ? result.short_name : null;
  };

  useImperativeHandle(ref, () => ({
    getCurrentLocation() {
      checkInLocations.push(checkInLocation); // will need to update database as well, and the checkInLocations array should be coming from database
      setCheckInLatitude(userLocation.lat);
      setCheckInLongitude(userLocation.lng);
      props.onCheckInLocationChange(checkInLatitude, checkInLongitude);
      return userLocation;
    },
    getIsLoaded() {
      return isLoaded;
    },
    geocodeLatLng(latitude, longitude) {
      const latlng = {
        lat: latitude,
        lng: longitude,
      };
      if (isLoaded) {
        if (geocoder == null) geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ latLng: latlng }, function (results, status) {
          if (
            status === window.google.maps.GeocoderStatus.OK &&
            results.length
          ) {
            results = results[0].address_components;
            let city = findResult(results, "locality");
            let state = findResult(results, "administrative_area_level_1");
            let country = findResult(results, "country");
            return city + ", " + state + ", " + country;
          }
        });
      }
    },
  }));

  // useMemo only runs when one of its dependencies update (returns a memoized value)
  let userLocation = useMemo(
    () => ({
      lat: userLatitude,
      lng: userLongitude,
    }),
    [userLatitude, userLongitude]
  );

  // for last checked in marker
  let checkInLocation = useMemo(
    () => ({
      lat: checkInLatitude,
      lng: checkInLongitude,
    }),
    [checkInLatitude, checkInLongitude]
  );

  const options = {
    zoomControl: true,
    enableHighAccuracy: false,
    mapId: "7713fa42e3ab204", // this is just for the map style, no need to protect
    clickableIcons: false,
    disableDefaultUI: true,
    watchPosition: true,
  };

  // need to figure out how to not let zooming reset
  // executes when userLatitude and userLongitude change

  useEffect(() => {
    if (navigator.geolocation) {
      let watchId = navigator.geolocation.watchPosition(
        (position) => {
          let prevLocation = {
            lat: userLatitude,
            lng: userLongitude,
          };
          setUserLatitude(position.coords.latitude);
          setUserLongitude(position.coords.longitude);
          setUserDirection(
            getCurrentDirection(prevLocation, {
              lat: userLatitude,
              lng: userLongitude,
            })
          );
        },
        (err) =>
          setGeolocationError(
            err.message +
              ". Please enable Geolocation to continue using our services."
          ),

        {
          enableHighAccuracy: false,
        }
      );
      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    } else {
      setGeolocationError("Your browser doesn't support geolocation.");
    }
  }, [userLatitude, userLongitude, checkInLocations]);

  return isLoaded && userLatitude != null && userLongitude != null ? (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      {props.viewerMode ? null : (
        <>
          <div>
            Radius{" "}
            <input
              type="range"
              value={radius}
              min="5"
              max="150"
              onChange={(e) => setRadius(Number(e.target.value))}
            />
            {radius} km
          </div>
          <div>
            <input
              type="checkbox"
              checked={showRadius}
              onChange={() => toggleRadius(!showRadius)}
            />
          </div>
        </>
      )}
      <div className="maps" style={{ height: "100%", width: "100%" }}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          onLoad={onLoad}
          center={userLocation}
          zoom={18}
          options={options}
        >
          {props.viewerMode ? null : (
            <>
              <Circle
                center={userLocation}
                radius={radius}
                options={{
                  strokeOpacity: 0.5,
                  strokeColor: "#9CAF88",
                  fillColor: "#9CAF88",
                  fillOpacity: 0.1,
                  visible: showRadius, // might add an option to toggle off visibility
                }}
              />

              <Marker
                position={userLocation}
                icon={{
                  path: "M11.4922 13.5079V12.5079H10.4922H2.24641C0.954684 12.5079 0.458121 10.6804 1.76819 10.0672C1.76873 10.0669 1.76928 10.0667 1.76983 10.0664L21.236 1.08195C21.6888 0.900453 22.1999 1.03069 22.5845 1.41526C22.969 1.7998 23.0995 2.3111 22.9181 2.76424L13.9337 22.2303C13.9334 22.2309 13.9331 22.2315 13.9328 22.2321C13.3198 23.5419 11.4922 23.0459 11.4922 21.7537V13.5079Z",
                  strokeColor: "#9CAF88",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  fillColor: "#9CAF88",
                  fillOpacity: 0.46,
                  rotation: userDirection,
                  scale: 1.0,
                  anchor: new window.google.maps.Point(0, 0),
                }}
              />
            </>
          )}
          {props.showCheckInLocations ? (
            <>
              <Marker
                position={
                  checkInLocation.lat == null || checkInLocation.lng == null
                    ? checkInLocations[checkInLocations.length - 1]
                    : checkInLocation
                }
                icon={{
                  path: "M5.65353 14.9607L5.65214 14.9589C4.91514 14.0019 4.0979 13.3115 3.57007 12.8656C3.49479 12.8021 3.4254 12.7434 3.36296 12.6897C1.78991 11.3246 0.5 9.42283 0.5 7.2C0.5 3.50414 3.50414 0.5 7.2 0.5C10.8959 0.5 13.9 3.50414 13.9 7.2C13.9 9.42282 12.6101 11.3246 11.0371 12.6897C10.9746 12.7434 10.9052 12.802 10.8299 12.8656C10.3021 13.3115 9.48485 14.0019 8.74785 14.9589L8.74647 14.9607C8.16806 15.7189 7.60178 16.5414 7.2 17.2817C6.79822 16.5414 6.23195 15.7189 5.65353 14.9607Z",
                  strokeColor: "#9CAF88",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  fillColor: "#9CAF88",
                  fillOpacity: 0.46,
                  rotation: 0,
                  scale: 1.0,
                  anchor: new window.google.maps.Point(0, 15),
                }}
              />
              <Polyline
                path={Object.values(
                  [].concat(checkInLocations, checkInLocation)
                ).filter((loc) => loc.lat != null && loc.lng != null)}
                options={{
                  strokeOpacity: 0,
                  icons: [
                    {
                      icon: {
                        path: "M0,5a5,5 0 1,0 10,0a5,5 0 1,0 -10,0",
                        strokeColor: "#9CAF88",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        fillColor: "#9CAF88",
                        fillOpacity: 0.46,
                        rotation: 0,
                        scale: 0.75,
                      },
                      offset: "50%",
                      repeat: "25px",
                      geodesic: false,
                    },
                  ],
                }}
              />
            </>
          ) : null}
          <>
            {
              // Displays all locations from checkInLocations array (represents the past locations the user stayed at)
              // might need to change this to use Polyline
              props.showCheckInLocations
                ? checkInLocations
                    .slice(0, checkInLocations.length - 1)
                    .map((marker, index) => {
                      return (
                        <Marker
                          key={index}
                          position={marker}
                          icon={{
                            path: "M5.65353 14.9607L5.65214 14.9589C4.91514 14.0019 4.0979 13.3115 3.57007 12.8656C3.49479 12.8021 3.4254 12.7434 3.36296 12.6897C1.78991 11.3246 0.5 9.42283 0.5 7.2C0.5 3.50414 3.50414 0.5 7.2 0.5C10.8959 0.5 13.9 3.50414 13.9 7.2C13.9 9.42282 12.6101 11.3246 11.0371 12.6897C10.9746 12.7434 10.9052 12.802 10.8299 12.8656C10.3021 13.3115 9.48485 14.0019 8.74785 14.9589L8.74647 14.9607C8.16806 15.7189 7.60178 16.5414 7.2 17.2817C6.79822 16.5414 6.23195 15.7189 5.65353 14.9607Z",
                            strokeColor: "#9CAF88",
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            fillColor: "#FFFFFF",
                            fillOpacity: 1,
                            rotation: 0,
                            scale: 1.0,
                            anchor: new window.google.maps.Point(0, 15),
                          }}
                        />
                      );
                    })
                : null
            }
          </>
        </GoogleMap>
      </div>
    </div>
  ) : geolocationError && !props.viewerMode ? (
    <GeolocationError message={geolocationError} />
  ) : (
    <div>Loading...</div>
  );
});

export default React.memo(MapComponent);
