import { PagePanel } from '@jam-dev/starlight-react';
import { FormContainer, FormRow, FormSubmit, FormText, FormCheckbox } from '@safe-traveler/react-component-lib';
import { ReactNode, useContext, useState } from 'react';
import friendsIllustration from '../../../assets/illustration/undraw_coffee_with_friends.svg';
import { ContactContext } from '../../../context/contact-context/ContactContext';
import { ContactContextActionTypes } from '../../../context/contact-context/ContactContextModels';
import './ContactEditPanel.css';
import { newUUID } from '@safe-traveler/safe-traveler-data-models/dist/type/UUID.js';
import { NotificationContext } from '../../../context/notification-context/NotificationContext';
import { NotificationContextActionTypes } from "../../../context/notification-context/NotificationContextModels";
import { ContactAPIModel } from '@safe-traveler/safe-traveler-data-models';
import { ProfileContext } from "../../../context/profile-context/ProfileContext";

interface ContactEditPanelProps {
  onClose?: () => any;
  open?: boolean;
  title: ReactNode;
}

export const ContactEditPanel = ({onClose, open, title}: ContactEditPanelProps) => {
  const { contactState, contactDispatch } = useContext(ContactContext.getContext());
  const { profileState, profileDispatch } = useContext(ProfileContext.getContext());
  const { notificationDispatch } = useContext(NotificationContext.getContext());
  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName]= useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [favorite, setFavorite] = useState(false);

  const addNotification = (value: string, ttlString: string) => {
    let ttl: number | undefined = parseInt(ttlString);
    notificationDispatch({
      type: NotificationContextActionTypes.ADD_NOTIFICATION,
      payload: { value, ttl }
    });
  }

  const handleSubmit = async () => {
    if (onClose) onClose();
    
    const newContact: ContactAPIModel = {
      contactId: newUUID(), 
      creatorId: profileState.profile[0].profileId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      favorite: favorite,
      blocked: false,
      contactEmail: true,             
      contactPhone: true
    }
    contactDispatch({
      type: ContactContextActionTypes.ADD_CONTACT,
      payload: {
        contact: newContact
      }
    });
    addNotification('Contact successfully added!', '20');
  }

  return (
    <PagePanel
      onClose={onClose}
      open={open ? true : false}
      title={title}
    >
      <img alt='Contact art' className='contacts-page-illustration' src={friendsIllustration} />
      <FormContainer onSubmit={handleSubmit}>
        <FormRow>
          <FormText id='contact-edit-first-name' initialValue={firstName} label='First Name' onChange={setFirstName} required/>
        </FormRow>
        <FormRow>
          <FormText id='contact-edit-last-name' initialValue={lastName} label='Last Name' onChange={setLastName} required/>
        </FormRow>
        <FormRow>
          <FormText id='contact-edit-phoneNumber' initialValue={phoneNumber} label='Phone Number' onChange={setPhoneNumber} required pattern='[0-9]{3}[0-9]{3}[0-9]{4}'/>
        </FormRow>
        <FormRow>
          <FormText id='contact-edit-email' initialValue={email} label='Email' onChange={setEmail} required pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}'/>
        </FormRow>
        <FormRow>
          <FormCheckbox id='contact-edit-fav' label='Set as favorite?' onCheck={setFavorite}></FormCheckbox>
        </FormRow>
        <FormRow>
          <FormSubmit />
        </FormRow>
      </FormContainer>
    </PagePanel>
  );
}
