import { PagePanel } from "@jam-dev/starlight-react";
import {
  FormContainer,
  FormLocation,
  FormRow,
  FormSubmit,
  FormText,
  FormTextArea,
  StarRating,
} from "@safe-traveler/react-component-lib";
import React, {
  ChangeEvent,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import examsIllustration from "../../../assets/illustration/undraw_exams.svg";
import { ReviewContext } from "../../../context/review-context/ReviewContext";
import { ReviewContextActionTypes } from "../../../context/review-context/ReviewContextModels";
import "./ReviewEditPanel.css";
import { newUUID } from "@safe-traveler/safe-traveler-data-models/dist/type/UUID.js";
import { usePlacesWidget } from "react-google-autocomplete";
//import { usePlacesWidget } from "react-google-autocomplete";

interface ReviewEditPanelProps {
  onClose?: () => any;
  open?: boolean;
  title: ReactNode;
}

export const ReviewEditPanel = ({
  onClose,
  open,
  title,
}: ReviewEditPanelProps) => {
  const { reviewState, reviewDispatch } = useContext(
    ReviewContext.getContext()
  );

  var profileId = sessionStorage.getItem("profileId");
  if (profileId == null) {
    profileId = "c1";
  }

  const [reviewsData, setReviewsData] = useState([]);
  const [rating, setRating] = useState(0);
  const [reviewTitle, setReviewTitle] = useState("");
  const [location, setLocation] = useState<string | null>(null);
  const [placeSelected, setPlaceSelected] = useState(false);
  const [body, setBody] = useState("");

  const handleSubmit = () => {
    if (profileId != null && location != null) {
      reviewDispatch({
        type: ReviewContextActionTypes.ADD_REVIEW,
        payload: {
          body: body,
          creatorId: profileId,
          datePosted: new Date().toString(),
          downvotes: 0,
          reviewId: newUUID(),
          rating: rating + "",
          title: reviewTitle,
          location: location,
          upvotes: 0,
        },
      });
    }
  };

  const handleRating = (rate: number) => {
    setRating(rate);
  };

  return (
    <PagePanel onClose={onClose} open={open ? true : false} title={title}>
      <img
        alt="Review art"
        className="location-review-page-illustration"
        src={examsIllustration}
      />

      <FormContainer onSubmit={handleSubmit}>
        <FormRow>
          <StarRating
            onClick={handleRating}
            display={false}
            rating={-1}
            numStars={5}
            precision={0.5}
          />
        </FormRow>
        <FormRow>
          <FormText
            id="reviewTitle"
            initialValue={reviewTitle}
            label="Review title"
            onChange={setReviewTitle}
          />
        </FormRow>
        <FormRow>

          <FormLocation labelText="Location: " onChange={setLocation} />
        </FormRow>
        <FormRow>
          <FormTextArea
            id="reviewBody"
            onChange={setBody}
            label="Review Body"
          />
        </FormRow>
        <FormRow>
          <FormSubmit />
        </FormRow>
      </FormContainer>
    </PagePanel>
  );
};
