import contactIcon from '../../../assets/icons/contact.svg';
import closeIcon from '../../../assets/icons/close.svg';
import './Contact.css';

interface ContactProps {
  contactFirstName: string;
  contactLastName: string;
  phone: string;
  email: string;
  onDelete?: () => any;
  onModify?: () => any;
}

const Contact = ({contactFirstName, contactLastName, phone, email, onDelete, onModify}: ContactProps) => {
  return (
    <div className="contact">
      <div className="contact-decoration">
        <img className="svg" src={contactIcon} alt="Contact Icon" width="24px" />
      </div>
      <div className="contact-details">
        <p className="contact-name" onClick={onModify}>{contactFirstName} {contactLastName}</p>
        <p className="phone-email">{phone}, {email}</p>
      </div>
      {
        onDelete ? 
        <img className='svg' src={closeIcon} alt={'Delete trip'} width="24px" onClick={onDelete} /> :
        null
      }
    </div>
  );
}

export default Contact;
