import { ContactAPIModel } from "@safe-traveler/safe-traveler-data-models";
import { useContext } from "react";
import { ContactContext } from "../../../context/contact-context/ContactContext";
import { ContactContextActionTypes } from "../../../context/contact-context/ContactContextModels";
import Contact from "../contact/Contact";
import "./ContactList.css";
import { useNavigate } from "react-router-dom";

interface ContactListProps {
  searchInput?: string;
  allowDelete?: boolean;
  allowModify?: boolean;
}

const ContactList = ({searchInput, allowDelete, allowModify}: ContactListProps) => {
  let navigate = useNavigate();

  const { contactState, contactDispatch } = useContext(ContactContext.getContext());
  const renderConacts = (): JSX.Element[] => {
    const contactElements: JSX.Element[] = [];
    const contactData: ContactAPIModel[] = contactState.contacts;

    // sort contacts in abc order
    const sortedData = contactData.sort(function (a, b) {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    });

    // get only contacts that match search query
    const filteredData = sortedData.filter((contacts) => {
      if (!searchInput) {
        return contacts;
      }
      else {
        return contacts.firstName.toLowerCase().startsWith(searchInput) || contacts.lastName.toLowerCase().startsWith(searchInput);
      }
    });
    
    filteredData.forEach((contact)=> {
      contactElements.push(
        <Contact
          contactFirstName={contact.firstName}
          contactLastName={contact.lastName}
          phone={contact.phoneNumber}
          email={contact.email}
          onDelete={allowDelete ? () => {handleDelete(contact.contactId)} : undefined}
          onModify={allowModify ? () => {handleModify(contact.contactId)} : undefined} 
        />
      );
    });

    return contactElements;
  }

  const handleDelete = (contactId: string) => {
    contactDispatch({
      type: ContactContextActionTypes.DELETE_CONTACT,
      payload: {
        contact: contactId
      }
    })
  }

  const handleModify = (contactId: string) => {
    navigate(`/contacts/${contactId}`);
  }

  return (
    <div className="contact-list">
      { renderConacts().length === 0 ? <div>No Results</div> : renderConacts()}
    </div>
  );
}

export default ContactList;