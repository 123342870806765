import { Banner, Button, Icon, PageContent } from "@jam-dev/starlight-react";
import { Fragment, useContext, useState } from "react";
import plusIcon from '../../assets/icons/plus.svg';
import friendsIllustration from '../../assets/illustration/undraw_coffee_with_friends.svg';
import Footer from "../../Components/common/footer/Footer";
import { ContactContext } from "../../context/contact-context/ContactContext";
import './ContactsPage.css';
import Search from '../../Components/common/search/Search';
import { ContactPreviewList, ContactPreviewProps } from "@safe-traveler/react-component-lib";
import { ContactAPIModel } from "@safe-traveler/safe-traveler-data-models";
import ContactList from '../../Components/common/contact-list/ContactList';
import { ContactEditPanel } from "./contact-edit-panel/ContactEditPanel";

export const ContactsPage = () => {
  const {contactState, contactDispatch} = useContext(ContactContext.getContext());
  const [editPanelTitle, setEditPanelTitle] = useState('Add contact');
  const [showEditPanel, setShowEditPanel] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const handleChange = (e: any) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearchInput(lowerCase);
  }

  const getContactPreviewListProps = (contactList: ContactAPIModel[]) => {
    const contactPreviewListProps: ContactPreviewProps[] = [];

    contactList.forEach(contactAPIModel => {
      contactPreviewListProps.push({
        contactName: contactAPIModel.firstName + " " + contactAPIModel.lastName,
        hasEmail: false,
        hasPhone: false
      });
    });

    return contactPreviewListProps;
  }

  const toggleEditPanel = () => {
    setEditPanelTitle('Add contact');
    setShowEditPanel(!showEditPanel);
  }

  return (
    <Fragment>
      <ContactEditPanel
        onClose={toggleEditPanel}
        open={showEditPanel}
        title={<h1>{editPanelTitle}</h1>}
      />
      <PageContent>
        <h1>Contacts</h1>
        <Search handleChange={handleChange} searchInput={searchInput}></Search>
        <img alt='Contacts art' className='contacts-page-illustration' src={friendsIllustration} />
        <Button className='contacts-page-add' onClick={toggleEditPanel}>
          <Icon alt='Add contact' src={plusIcon} />
        </Button>
        {
          contactState.contacts.length > 0 ?
          <ContactList searchInput={searchInput} allowDelete allowModify/> :
          <Banner className='contacts-page-no-contact-message'>
            <p>No contacts added. Add contacts using the button above.</p>
          </Banner>
        }
      </PageContent>
      <Footer />
    </Fragment>
  )
}