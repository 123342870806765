// import axios from "axios";
import { Context, useEffect, useReducer } from "react";
import { ReviewContext } from "./ReviewContext";
import {
  ReviewContextActionTypes,
  ReviewContextValue,
} from "./ReviewContextModels";
import { ReviewAPI } from "../../api/ReviewAPI";
import { ReviewVotesAPI } from "../../api/ReviewVotesAPI";

const ReactContext: Context<ReviewContextValue> = ReviewContext.getContext();

/**
 * Review context provider component necessary for holding the reducer and
 * providing context to descendent React components.
 *
 * All descendent components can access the values within Context.Provider's
 * value attribute using the following code:
 * const { reviewState, reviewDispatch } =
 *     React.useContext(ReviewContext.getContext());
 */
const ReviewContextProvider = ({ children }: any) => {
  const [reviewState, reviewDispatch] = useReducer(
    ReviewContext.reducer,
    ReviewContext.getInitialState()
  );

  useEffect(() => {
    const getReviews = async () => {
      const response = await ReviewAPI.getAllReviews();
      reviewDispatch({
        type: ReviewContextActionTypes.ADD_REVIEWS,
        payload: response,
      });
    };

    const getReviewVotes = async () => {
      const response = await ReviewVotesAPI.getAllReviewVotes();
      reviewDispatch({
        type: ReviewContextActionTypes.ADD_REVIEW_VOTES,
        payload: response,
      });
    };

    getReviews();
    getReviewVotes();
  }, []);

  return (
    <ReactContext.Provider value={{ reviewState, reviewDispatch }}>
      {children}
    </ReactContext.Provider>
  );
};

export default ReviewContextProvider;
