import {ItineraryAPIModel} from '@safe-traveler/safe-traveler-data-models';
import { Dispatch } from 'react';

/**
 * Core types required for itinerary context to function
 */
// state and dispatch pointers that are exposed by the itinerary context.
export interface ItineraryContextValue {
    itineraryState: ItineraryContextState;
    itineraryDispatch: ItineraryContextDispatch;
}

// Contains the data of the itinerary context.
export interface ItineraryContextState {
    itineraryList: ItineraryAPIModel[],
}

// Type of reducer dispatch function for itinerary context.
export type ItineraryContextDispatch = Dispatch<
    ItineraryContextSyncEventsAction |
    ItineraryContextAddEventAction |
    ItineraryContextAddEventsAction |
    ItineraryContextDeleteEventAction |
    ItineraryContextDeleteEventsAction |
    ItineraryContextReplaceEventAction
    >;

// Inputs for itinerary context dispatch funcntion.
export interface ItineraryContextSyncEventsAction {
    type: ItineraryContextActionTypes.SYNC_EVENTS;
    payload: ItineraryAPIModel[];
}

// Inputs for itinerary context dispatch funcntion.
export interface ItineraryContextAddEventAction {
    type: ItineraryContextActionTypes.ADD_EVENT,
    payload: ItineraryAPIModel
}

export interface ItineraryContextAddEventsAction {
    type: ItineraryContextActionTypes.ADD_EVENTS,
    payload: ItineraryAPIModel[]
}

export interface ItineraryContextDeleteEventAction {
    type: ItineraryContextActionTypes.DELETE_EVENT,
    payload: string
}

export interface ItineraryContextDeleteEventsAction {
    type: ItineraryContextActionTypes.DELETE_EVENTS,
    payload: string[]
}

export interface ItineraryContextReplaceEventAction {
    type: ItineraryContextActionTypes.REPLACE_EVENT,
    payload: ItineraryAPIModel
}


export enum ItineraryContextActionTypes {
    SYNC_EVENTS,
    ADD_EVENT,
    ADD_EVENTS,
    DELETE_EVENT,
    DELETE_EVENTS,
    REPLACE_EVENT
}
