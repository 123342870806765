import { APIManager } from './APIManager';
import {ItineraryAPIModel} from "@safe-traveler/safe-traveler-data-models";

export class ItineraryAPI extends APIManager {

  // GET /itinerary
  public static async getAllEvents() {
    const response = await this.getRequest('/v1/itinerary');
    console.log("GET ALL EVENTS RESPONSE: ");
    console.log(response);
    return response.data;
  }

  // PUT /itinerary
  public static async putEvent(eventObj: ItineraryAPIModel): Promise<ItineraryAPIModel> {
    const response = await this.putRequest('/v1/itinerary', {}, JSON.stringify(eventObj));
    return response.data;
  }

  // DELETE /itinerary/{event-id}
  public static async deleteEvent(eventID: string) {
    const response = await this.deleteRequest(`/v1/itinerary/${eventID}`);
    return response.data;
  }

  // GET /itinerary/{event-id}
  public static async getEvent(eventID: string) {
    const response = await this.getRequest(`/v1/itinerary/${eventID}`);
    return response.data;
  }
}
