import { Banner, Button, Icon, PageContent } from "@jam-dev/starlight-react";
import { TripPreviewList, TripPreviewProps } from "@safe-traveler/react-component-lib";
import { TripHistoryAPIModel } from "@safe-traveler/safe-traveler-data-models";
import { Fragment, useContext, useState } from "react";
import contentSaveIcon from '../../assets/icons/content-save.svg';
import mapMarkerIcon from '../../assets/icons/map-marker.svg';
import pencilIcon from '../../assets/icons/pencil.svg';
import travelingIllustraction from '../../assets/illustration/undraw_traveling.svg';
import Footer from "../../Components/common/footer/Footer";
import { ContactContext } from "../../context/contact-context/ContactContext";
import { TripContext } from "../../context/trip-context/TripContext";
import { TripContextActionTypes } from "../../context/trip-context/TripContextModels";
import './TripsHistoryPage.css';

export const TripsHistoryPage = () => {
  const { tripState, tripDispatch } = useContext(TripContext.getContext());
  const { contactState } = useContext(ContactContext.getContext());
  const [allowEdit, setAllowEdit] = useState(false);

  const getTripPreviewListProps = (tripList: TripHistoryAPIModel[]) => {
    const tripPreviewListProps: TripPreviewProps[] = [];

    tripList.forEach(tripAPIModel => {
      const startTime = new Date(tripAPIModel.startTime);
      const endTime = new Date(tripAPIModel.endTime);

      tripPreviewListProps.push({
        contacts: contactState.contacts.filter(e => tripAPIModel.contacts.includes(e.contactId)).map(e => `${e.firstName} ${e.lastName}`),
        location: tripAPIModel.tripCity,
        time: `${startTime.toDateString()} ${startTime.getHours()}:00 to ${endTime.toDateString()} ${endTime.getHours()}:00`,
        title: tripAPIModel.tripName,
        tripID: tripAPIModel.tripID,
      });
    });

    return tripPreviewListProps;
  }

  const toggleEditEntries = () => {
    setAllowEdit(!allowEdit);
  }

  const handleDelete = (tripID: string) => {
    tripDispatch({
      type: TripContextActionTypes.DELETE_TRIP_HISTORY,
      payload: tripID,
    })
  }

  return (
    <Fragment>
      <PageContent>
      <div className='trips-history-page-title-row'>
          <h1>Trips History</h1>
          {
            tripState.tripHistoryList.length > 0 ?
            <Button onClick={toggleEditEntries}>
              <Icon alt='Edit' src={allowEdit ? contentSaveIcon : pencilIcon } />
            </Button> :
            null
          }
        </div>
        <img alt='Trip art' className='trips-history-page-illustration' src={travelingIllustraction} />
        {
          tripState.tripHistoryList.length > 0 ?
          <TripPreviewList
            onDelete={allowEdit ? handleDelete : undefined}
            trips={getTripPreviewListProps(tripState.tripHistoryList)}
          /> :
          <Banner className='trips-history-page-no-trip-message'>
            <Icon alt='Location icon' src={mapMarkerIcon} />
            <p>No trips history. Items will show up when trips have been deleted.</p>
          </Banner>
        }
      </PageContent>
      <Footer />
    </Fragment>
  )
}
