import { Dispatch } from 'react';
import { Icon } from '../../data/Icon';

/**
 * Core types required for sidenav context to function
 */
// state and dispatch pointers that are exposed by the sidenav context.
export interface SidenavContextValue {
  sidenavState: SidenavContextState;
  sidenavDispatch: SidenavContextDispatch;
}

// Contains the data of the sidenav context.
export interface SidenavContextState {
  isOpen: boolean;
  routes: SidenavRoute[];
}

// Type of reducer dispatch function for sidenav context.
export type SidenavContextDispatch = Dispatch<SidenavContextReducerAction>;

// Input for sidenav context dispatch funcntion.
export interface SidenavContextReducerAction {
  type: SidenavContextActionTypes;
  payload?: any,
}

export enum SidenavContextActionTypes {
  OPEN_SIDENAV,
  CLOSE_SIDENAV,
  TOGGLE_SIDENAV,
}

/**
 * Types used in sidenav context state
 */
export interface SidenavRoute {
  icon?: Icon;
  route: string;
  routeName: string;
}
