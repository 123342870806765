import { AxiosResponse } from 'axios';
import { APIManager } from './APIManager';
import { ContactAPIModel } from '@safe-traveler/safe-traveler-data-models';

export class ContactsAPI extends APIManager {
  // GET /contacts
  public static async getAllContacts(): Promise<ContactAPIModel[]> {
    const response = await this.getRequest('/v1/contacts');
    if (response.status != 200) {
      generateError(response);
    }
    return response.data as ContactAPIModel[];
  }

  // PUT /contacts
  public static async putContact(contact: ContactAPIModel) {
    const response = await this.putRequest('/v1/contacts', {}, JSON.stringify(contact));
    if (response.status != 200) {
      generateError(response);
    }
    return response;
  }

  // GET /contacts/{contact-id}
  public static async getContact(contactID: string) {
    const response = await this.getRequest('/v1/contacts/' + contactID);
    if (response.status != 200) {
      generateError(response);
    }
    return response.data as ContactAPIModel;
  }

  // DELETE /contacts/{contact-id}
  public static async deleteContact(contactID: string) {
    const response = await this.deleteRequest('/v1/contacts/' + contactID);
    if (response.status != 200) {
      generateError(response);
    }
    return response;
  }
}

function generateError(response: AxiosResponse<any, any>) {
  throw new Error("Error!\n" + "Status: " + response.status + "Msg: " + response.statusText);
}