import { Dispatch } from "react";
import {
  ReviewAPIModel,
  ReviewVotesAPIModel,
} from "@safe-traveler/safe-traveler-data-models";

/**
 * Core types required for review context to function
 */
// state and dispatch pointers that are exposed by the review context.
export interface ReviewContextValue {
  reviewState: ReviewContextState;
  reviewDispatch: ReviewContextDispatch;
}

// Contains the data of the review context.
export interface ReviewContextState {
  reviewList: ReviewAPIModel[];
  reviewVotesList: ReviewVotesAPIModel[];
}

// Type of reducer dispatch function for trip context.
export type ReviewContextDispatch = Dispatch<
  | ReviewContextAddReviewAction
  | ReviewContextAddReviewsAction
  | ReviewContextDeleteReviewAction
  | ReviewContextDeleteReviewsAction
  | ReviewContextEditReviewAction
  | ReviewContextAddReviewVoteAction
  | ReviewContextAddReviewVotesAction
  | ReviewContextDeleteReviewVoteAction
  | ReviewContextDeleteReviewVotesAction
>;

export enum ReviewContextActionTypes {
  ADD_REVIEW,
  ADD_REVIEWS,
  DELETE_REVIEW,
  DELETE_REVIEWS,
  EDIT_REVIEW,
  ADD_REVIEW_VOTE,
  ADD_REVIEW_VOTES,
  DELETE_REVIEW_VOTE,
  DELETE_REVIEW_VOTES,
}

// Inputs for trip context dispatch funcntion.
export interface ReviewContextAddReviewAction {
  type: ReviewContextActionTypes.ADD_REVIEW;
  payload: ReviewAPIModel;
}

export interface ReviewContextAddReviewsAction {
  type: ReviewContextActionTypes.ADD_REVIEWS;
  payload: ReviewAPIModel[];
}

export interface ReviewContextDeleteReviewAction {
  type: ReviewContextActionTypes.DELETE_REVIEW;
  payload: string;
}

export interface ReviewContextDeleteReviewsAction {
  type: ReviewContextActionTypes.DELETE_REVIEWS;
  payload: string[];
}

export interface ReviewContextEditReviewAction {
  type: ReviewContextActionTypes.EDIT_REVIEW;
  payload: ReviewAPIModel;
}

export interface ReviewContextAddReviewVoteAction {
  type: ReviewContextActionTypes.ADD_REVIEW_VOTE;
  payload: ReviewVotesAPIModel;
}

export interface ReviewContextAddReviewVotesAction {
  type: ReviewContextActionTypes.ADD_REVIEW_VOTES;
  payload: ReviewVotesAPIModel[];
}

export interface ReviewContextDeleteReviewVoteAction {
  type: ReviewContextActionTypes.DELETE_REVIEW_VOTE;
  payload: string;
}

export interface ReviewContextDeleteReviewVotesAction {
  type: ReviewContextActionTypes.DELETE_REVIEW_VOTES;
  payload: string[];
}
