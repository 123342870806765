// dateTimeFormat example: Mon Oct 31 2022 15:02:46 GMT-0400 (Eastern Daylight Time)
// returns date in the following format: Oct 31, 2022
export function getDate(dateTimeFormat) {
    const date = dateTimeFormat.toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });
    return date;
}

// dateTimeFormat example: Mon Oct 31 2022 15:02:46 GMT-0400 (Eastern Daylight Time)
// returns time in the following format: 03:02 PM
export function getTime(dateTimeFormat) {
    const time = dateTimeFormat.toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit'
    });
    return time;
}
