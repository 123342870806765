import { Context, createContext } from "react";
import { ContactsAPI } from "../../api/ContactsAPI";
import {
  ContactContextActionTypes,
  ContactContextAddContactPayload,
  ContactContextAddContactsPayload,
  ContactContextDeleteContactPayload,
  ContactContextDeleteContactsPayload,
  ContactContextEditContactsPayload,
  ContactContextReducerAction,
  ContactContextState,
  ContactContextValue,
} from "./ContactContextModels";

const initialState: ContactContextState = {
  contacts: [],
};

/**
 * Facade class for contact context. This class only contains static methods for
 * accessing and modifying the contact context.
 *
 * When adding complex functionality to the reducer, create additional static
 * helper methods below and call them within reducer().
 */
export class ContactContext {
  private static Context: Context<ContactContextValue> = createContext(
    {} as ContactContextValue
  );
  private static initialState = initialState;

  public static getContext(): Context<ContactContextValue> {
    return ContactContext.Context;
  }

  public static getInitialState(): ContactContextState {
    return ContactContext.initialState;
  }

  /**
   * Takes the old state and an action as argument, and returns a new state
   * with the same shape as the old state. Called when a component uses the
   * contactDispatch() method declared in ContactContextProvider.
   *
   * IMPORTANT! The new state must not be the same object reference as the
   * previous state, hence why we create a new state object with JSON parse and
   * JSON stringify.
   *
   * The action object is an arbitrary object that contains two fields:
   * type: an identifier for the action that should be performed, e.g. update
   * array, remove from array, etc.
   * payload: an arbitrary payload containing information for the reducer to
   * use depending on the action type.
   */
  public static reducer(
    prevState: ContactContextState,
    action: ContactContextReducerAction
  ): ContactContextState {
    const state: ContactContextState = JSON.parse(JSON.stringify(prevState));

    switch (action.type) {
      case ContactContextActionTypes.ADD_CONTACT:
        const addContactPayload = action.payload as ContactContextAddContactPayload;
        state.contacts.push(addContactPayload.contact);
        ContactsAPI.putContact(addContactPayload.contact);
        break;
      case ContactContextActionTypes.ADD_CONTACTS:
        const addContactsPayload = action.payload as ContactContextAddContactsPayload;
        state.contacts = state.contacts.concat(addContactsPayload.contacts);
        break;
      case ContactContextActionTypes.DELETE_CONTACT:
        const deleteContactPayload = action.payload as ContactContextDeleteContactPayload;
        state.contacts = state.contacts.filter(
          (e) => e.contactId !== deleteContactPayload.contact
        );
        ContactsAPI.deleteContact(deleteContactPayload.contact);
        break;
      case ContactContextActionTypes.DELETE_CONTACTS:
        const deleteContactsPayload = action.payload as ContactContextDeleteContactsPayload;
        deleteContactsPayload.contacts.forEach((contactId) => {
          state.contacts = state.contacts.filter(
            (e) => e.contactId !== contactId
          );
        });
        break;
      case ContactContextActionTypes.EDIT_CONTACT:
        const editContactPayload = action.payload as ContactContextEditContactsPayload;
        state.contacts = state.contacts.map((e) => e.contactId !== editContactPayload.contact.contactId ? e : editContactPayload.contact);
        ContactsAPI.putContact(editContactPayload.contact);
        break;
      default:
        throw Error(
          `ContactContext reducer: unsupported action type ${action.type}`
        );
    }

    return state;
  }
}
