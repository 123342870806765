import { Context, useReducer } from "react";
import { AuthContext } from "./AuthContext";
import { AuthContextValue } from "./AuthContextModels";

const ReactContext: Context<AuthContextValue> = AuthContext.getContext();

/**
 * Auth context provider component necessary for holding the reducer and
 * providing context to descendent React components.
 * 
 * All descendent components can access the values within Context.Provider's
 * value attribute using the following code:
 * const { authState, authDispatch } =
 *     React.useContext(AuthContext.getContext());
 */
const AuthContextProvider = ({children}: any) => {
  const [authState, authDispatch] = useReducer(AuthContext.reducer, AuthContext.getInitialState());

  return (
    <ReactContext.Provider value={{authState, authDispatch}}>
      {children}
    </ReactContext.Provider>
  );
}

export default AuthContextProvider;
