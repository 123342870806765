import { Context, createContext } from "react";
import { NotificationContextActionTypes, NotificationContextReducerAction, NotificationContextState, NotificationContextValue } from "./NotificationContextModels";

const initialState: NotificationContextState = {
  notifications: []
};

/**
 * Facade class for notification context. This class only contains static methods for
 * accessing and modifying the notification context.
 * 
 * When adding complex functionality to the reducer, create additional static
 * helper methods below and call them within reducer().
 */
export class NotificationContext {

  private static Context: Context<NotificationContextValue> = createContext({} as NotificationContextValue);
  private static initialState = initialState;

  public static getContext(): Context<NotificationContextValue> {
    return NotificationContext.Context;
  }

  public static getInitialState(): NotificationContextState {
    return NotificationContext.initialState;
  }

  /**
   * Takes the old state and an action as argument, and returns a new state
   * with the same shape as the old state. Called when a component uses the
   * notificationDispatch() method declared in NotificationContextProvider.
   * 
   * IMPORTANT! The new state must not be the same object reference as the
   * previous state, hence why we create a new state object with JSON parse and
   * JSON stringify.
   * 
   * The action object is an arbitrary object that contains two fields:
   * type: an identifier for the action that should be performed, e.g. update
   * array, remove from array, etc.
   * payload: an arbitrary payload containing information for the reducer to
   * use depending on the action type.
   */
  public static reducer(prevState: NotificationContextState, action: NotificationContextReducerAction): NotificationContextState {
    const state: NotificationContextState = JSON.parse(JSON.stringify(prevState));

    switch (action.type) {
      case NotificationContextActionTypes.ADD_NOTIFICATION:
        state.notifications.push({
          ...action.payload,
          id: Math.random().toString()
        });
        break;
      case NotificationContextActionTypes.DELETE_NOTIFICATION:
        state.notifications = state.notifications.filter(e => e.id != action.payload);
        break;
      default:
        throw Error(`NotificationContext reducer: unsupported action type ${action.type}`);
    }

    return state;
  }
}
