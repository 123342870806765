import { Context, useReducer } from "react";
import { SidenavContext } from "./SidenavContext"
import { SidenavContextValue } from "./SidenavContextModels";

const ReactContext: Context<SidenavContextValue> = SidenavContext.getContext();

/**
 * Sidenav context provider component necessary for holding the reducer and
 * providing context to descendent React components.
 * 
 * All descendent components can access the values within Context.Provider's
 * value attribute using the following code:
 * const { sidenavState, sidenavDispatch } =
 *     React.useContext(SidenavContext.getContext());
 */
const SidenavContextProvider = ({children}: any) => {
  const [sidenavState, sidenavDispatch] = useReducer(SidenavContext.reducer, SidenavContext.getInitialState());

  return (
    <ReactContext.Provider value={{sidenavState, sidenavDispatch}}>
      {children}
    </ReactContext.Provider>
  );
}

export default SidenavContextProvider;
