import { PagePanel } from '@jam-dev/starlight-react';
import { FormContainer, FormDate, FormRow, FormSelectChips, FormSubmit, FormText } from '@safe-traveler/react-component-lib';
import { ContactAPIModel, TripAPIModel } from '@safe-traveler/safe-traveler-data-models';
import { newUUID } from '@safe-traveler/safe-traveler-data-models/dist/type/UUID';
import { ReactNode, useContext, useState } from 'react';
import { SMSAPI } from '../../../api/SMSAPI';
import examsIllustraction from '../../../assets/illustration/undraw_exams.svg';
import { ContactContext } from '../../../context/contact-context/ContactContext';
import { ProfileContext } from '../../../context/profile-context/ProfileContext';
import { TripContext } from '../../../context/trip-context/TripContext';
import { TripContextActionTypes } from '../../../context/trip-context/TripContextModels';
import './TripEditPanel.css';

interface TripEditPanelProps {
  onClose?: () => any;
  open?: boolean;
  prefillModel?: TripAPIModel;
  title: ReactNode;
}

export const TripEditPanel = ({onClose, open, prefillModel, title}: TripEditPanelProps) => {
  const { tripDispatch } = useContext(TripContext.getContext());
  const { contactState } = useContext(ContactContext.getContext());
  const { profileState } = useContext(ProfileContext.getContext())
  const [tripName, setTripName] = useState(prefillModel?.tripName || '');
  const [location, setLocation] = useState(prefillModel?.tripCity || '');
  const [startTime, setStartTime] = useState(prefillModel?.startTime ? new Date(prefillModel.startTime) : new Date());
  const [endTime, setEndTime] = useState(prefillModel?.endTime ? new Date(prefillModel.endTime) : new Date(startTime.getTime() + 24 * 60 * 60 * 1000));
  const [contacts, setContacts] = useState<ContactAPIModel[]>([]);

  const formatDate = (date: Date) => {
    const day = [
      date.getFullYear(),
      (date.getMonth() + 1).toString().padStart(2, "0"),
      date.getDate().toString().padStart(2, "0"),
    ].join("-");

    return day + "T09:00";
  };

  const handleSubmit = async () => {
    if (tripName === "") return;
    if (location === "") return;
    if (contacts.length === 0) return;

    if (onClose) onClose();
    tripDispatch({
      type: TripContextActionTypes.ADD_TRIP,
      payload: {
        checkinLocations: ["{}"],
        contacts: contacts.map((e) => e.contactId),
        endTime: endTime.getTime(),
        profileID: "c1",
        startTime: startTime.getTime(),
        tripCity: location,
        tripID: newUUID(),
        tripName: tripName,
        lastCheckInDateTime: 0,
      },
    });

    console.log(profileState.profile[0].firstName);
    contacts.forEach(async (element) => {
      var phoneNumber = element.phoneNumber;

      var text =
        `${profileState.profile[0].firstName} ${profileState.profile[0].lastName} put you as an emergency contact for their upcoming trip! ` +
        `Go to https://www.safetraveler.me to verify your identity when you get a link from us.`;

      const data = {
        message: text,
        toPhoneNumber: phoneNumber,
        fromPhoneNumber: "+14246552880",
      };
      console.log(element.phoneNumber, element.firstName);
      const response = await SMSAPI.postSendText(
        undefined,
        JSON.stringify(data)
      );
      console.log(response);
    });

    var text = `Hello from SafeTraveler! We just wanted to remind you that you added a trip named ${tripName} for today on our website!
    Remember to Check In occassionally on our website and have fun.`;

    const d = new Date(startTime.getTime());
    const data = {
      message: text,
      toPhoneNumber: profileState.profile[0].phoneNumber,
      fromPhoneNumber: "+14246552880",
      sendAt: d.toISOString(),
      messagingServiceSid: "MGdbfe8d8a7a9ffe121b51504394990b37",
      scheduleType: "fixed",
    };
    const response = await SMSAPI.postSendTextFixed(
      undefined,
      JSON.stringify(data)
    );
    console.log(response);
  };

  const handleContactsChange = (contacts: string[]) => {
    const newContacts = contactState.contacts.filter((e) => {
      const firstLast = `${e.firstName} ${e.lastName}`;
      return contacts.includes(firstLast);
    });

    setContacts(newContacts);
  };

  return (
    <PagePanel onClose={onClose} open={open ? true : false} title={title}>
      <img
        alt="Trip art"
        className="trips-page-illustration"
        src={examsIllustraction}
      />
      <FormContainer onSubmit={handleSubmit}>
        <FormRow>
          <FormText
            id="trip-edit-name"
            initialValue={tripName}
            label="Trip name"
            onChange={setTripName}
          />
        </FormRow>
        <FormRow>
          <FormText
            id="trip-edit-location"
            initialValue={location}
            label="Location (city)"
            onChange={setLocation}
          />
        </FormRow>
        <FormRow>
          <FormDate
            id="trip-edit-start"
            initialValue={formatDate(startTime)}
            label="Start time"
            onChange={setStartTime}
          />
          <FormDate
            id="trip-edit-end"
            initialValue={formatDate(endTime)}
            onChange={setEndTime}
          />
        </FormRow>
        <FormRow>
          <FormSelectChips
            id="trip-edit-contacts"
            label="Contacts"
            onChange={handleContactsChange}
            options={contactState.contacts.map((e) => {
              return {
                name: `${e.firstName} ${e.lastName}`,
                value: e.contactId,
              };
            })}
            placeholder="Pick contacts"
          />
        </FormRow>
        <FormRow>
          <FormSubmit />
        </FormRow>
      </FormContainer>
    </PagePanel>
  );
};
