import { TripAPIModel } from '@safe-traveler/safe-traveler-data-models';
import { APIManager } from './APIManager';

export class TripsAPI extends APIManager {

  // GET /trips
  public static async getAllTrips(): Promise<TripAPIModel[]> {
    const response = await this.getRequest('/v1/trips');
    return response.data as TripAPIModel[];
  }

  // PUT /trips
  public static async putTrip(trip: TripAPIModel) {
    const response = await this.putRequest('/v1/trips', {}, JSON.stringify(trip));
    return response;
  }

  // DELETE /trips/{trip-id}
  public static async deleteTrip(tripID: string) {
    const response = await this.deleteRequest(`/v1/trips/${tripID}`);
    return response;
  }

  // GET /trips/{trip-id}
  public static async getTrip(tripID: string): Promise<TripAPIModel> {
    const response = await this.getRequest(`/v1/trips/${tripID}`);
    return response.data as TripAPIModel;
  }
}
