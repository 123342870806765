import { Context, createContext } from "react";
import {
  ReviewContextActionTypes,
  ReviewContextAddReviewAction,
  ReviewContextAddReviewsAction,
  ReviewContextDeleteReviewAction,
  ReviewContextDeleteReviewsAction,
  ReviewContextEditReviewAction,
  ReviewContextAddReviewVoteAction,
  ReviewContextAddReviewVotesAction,
  ReviewContextDeleteReviewVoteAction,
  ReviewContextDeleteReviewVotesAction,
  ReviewContextState,
  ReviewContextValue,
} from "./ReviewContextModels";
import { ReviewAPI } from "../../api/ReviewAPI";
import { ReviewVotesAPI } from "../../api/ReviewVotesAPI";

const initialState: ReviewContextState = {
  reviewList: [],
  reviewVotesList: [],
};

/**
 * Facade class for review context. This class only contains static methods for
 * accessing and modifying the review context.
 *
 * When adding complex functionality to the reducer, create additional static
 * helper methods below and call them within reducer().
 */
export class ReviewContext {
  private static Context: Context<ReviewContextValue> = createContext(
    {} as ReviewContextValue
  );
  private static initialState = initialState;

  public static getContext(): Context<ReviewContextValue> {
    return ReviewContext.Context;
  }

  public static getInitialState(): ReviewContextState {
    return ReviewContext.initialState;
  }

  /**
   * Takes the old state and an action as argument, and returns a new state
   * with the same shape as the old state. Called when a component uses the
   * reviewDispatch() method declared in ReviewContextProvider.
   *
   * IMPORTANT! The new state must not be the same object reference as the
   * previous state, hence why we create a new state object with JSON parse and
   * JSON stringify.
   *
   * The action object is an arbitrary object that contains two fields:
   * type: an identifier for the action that should be performed, e.g. update
   * array, remove from array, etc.
   * payload: an arbitrary payload containing information for the reducer to
   * use depending on the action type.
   */
  public static reducer(
    prevState: ReviewContextState,
    action:
      | ReviewContextAddReviewAction
      | ReviewContextAddReviewsAction
      | ReviewContextDeleteReviewAction
      | ReviewContextDeleteReviewsAction
      | ReviewContextEditReviewAction
      | ReviewContextAddReviewVoteAction
      | ReviewContextAddReviewVotesAction
      | ReviewContextDeleteReviewVoteAction
      | ReviewContextDeleteReviewVotesAction
  ): ReviewContextState {
    const state: ReviewContextState = JSON.parse(JSON.stringify(prevState));

    switch (action.type) {
      case ReviewContextActionTypes.ADD_REVIEW:
        state.reviewList.push(action.payload);
        console.log(action.payload);
        ReviewAPI.putReview(action.payload);
        break;
      case ReviewContextActionTypes.ADD_REVIEWS:
        state.reviewList = state.reviewList.concat(action.payload);
        break;
      case ReviewContextActionTypes.DELETE_REVIEW:
        state.reviewList = state.reviewList.filter(
          (e) => e.reviewId !== action.payload
        );
        ReviewAPI.deleteReview(action.payload);
        break;
      case ReviewContextActionTypes.DELETE_REVIEWS:
        action.payload.forEach((deleteReviewID) => {
          state.reviewList = state.reviewList.filter(
            (e) => e.reviewId !== deleteReviewID
          );
        });
        break;
      case ReviewContextActionTypes.EDIT_REVIEW:
        state.reviewList = state.reviewList.map((e) => e.reviewId !== action.payload.reviewId ? e : action.payload);
        ReviewAPI.putReview(action.payload);
        break;
      case ReviewContextActionTypes.ADD_REVIEW_VOTE:
        state.reviewVotesList.push(action.payload);
        ReviewVotesAPI.putReviewVote(action.payload);
        break;
      case ReviewContextActionTypes.ADD_REVIEW_VOTES:
        state.reviewVotesList = state.reviewVotesList.concat(action.payload);
        break;
      case ReviewContextActionTypes.DELETE_REVIEW_VOTE:
        state.reviewVotesList = state.reviewVotesList.filter(
          (e) => e.reviewId !== action.payload
        );
        ReviewVotesAPI.deleteReviewVotes(action.payload);
        break;
      case ReviewContextActionTypes.DELETE_REVIEW_VOTES:
        action.payload.forEach((deleteReviewID) => {
          state.reviewVotesList = state.reviewVotesList.filter(
            (e) => e.reviewId !== deleteReviewID
          );
          ReviewVotesAPI.deleteReviewVotes(deleteReviewID);
        });
        break;
      default:
        throw Error(`ReviewContext reducer: unsupported action type ${action}`);
    }

    return state;
  }
}
