import React from "react";
import { useNavigate } from "react-router-dom";
import {
  browserName,
  isChrome,
  isChromium,
  isEdge,
  isFirefox,
  isIE,
  isMacOs,
  isMobile,
  isOpera,
  isSafari,
  isSamsungBrowser,
  isYandex,
} from "react-device-detect";
import "./GeolocationError.css";

export interface GeolocationErrorProps {
  message: string;
}

const GeolocationError = ({ message }: GeolocationErrorProps) => {
  let instructions = null;

  if (isOpera) {
    instructions = "https://help.opera.com/en/latest/web-preferences/#location";
  } else if (isChrome || isChromium) {
    instructions =
      "https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop&oco=1";
  } else if (isFirefox) {
    instructions =
      "https://support.mozilla.org/en-US/kb/does-firefox-share-my-location-websites#w_how-do-i-undo-a-permission-granted-to-a-site";
  } else if (isIE) {
    instructions =
      "https://support.microsoft.com/en-us/windows/change-security-and-privacy-settings-for-internet-explorer-11-9528b011-664c-b771-d757-43a2b78b2afe";
  } else if (isEdge) {
    instructions =
      "https://support.microsoft.com/en-us/microsoft-edge/location-and-privacy-in-microsoft-edge-31b5d154-0b1b-90ef-e389-7c7d4ffe7b04#:~:text=To%20allow%20Microsoft%20Edge%20to,to%20access%20your%20precise%20location";
  } else if (isSafari && isMobile) {
    instructions = "https://support.apple.com/en-us/HT207092";
  } else if (isSafari && isMacOs) {
    instructions =
      "https://support.apple.com/guide/mac-help/allow-apps-to-detect-the-location-of-your-mac-mh35873/mac#:~:text=use%20Location%20Services-,On%20your%20Mac%2C%20choose%20Apple%20menu%20%3E%20System%20Preferences%2C%20click,%26%20Privacy%20%2C%20then%20click%20Privacy.&text=pane%20for%20me-,Click%20Location%20Services.,it%20to%20use%20Location%20Services";
  } else if (isYandex) {
    instructions =
      "https://yandex.com/support/common/browsers-settings/geo-ya.html";
  } else if (isSamsungBrowser) {
    instructions =
      "https://www.samsung.com/latin_en/support/mobile-devices/how-to-activate-my-location-and-change-settings-for-location-permissions/";
  }

  return (
    <div className="error-box">
      <h2>Error</h2>
      <hr />
      <p>{message}</p>

      {instructions ? (
        <p>
          Check out <a href={instructions}>this website</a> for instructions on
          how to enable your location in {browserName}.
        </p>
      ) : null}
    </div>
  );
};

export default GeolocationError;
