import "@jam-dev/starlight-react/css";
import "@safe-traveler/react-component-lib/css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AuthContextProvider from "./context/auth-context/AuthContextProvider";
import ContactContextProvider from "./context/contact-context/ContactContextProvider";
import ItineraryContextProvider from "./context/itinerary-context/ItineraryContextProvider";
import NotificationContextProvider from "./context/notification-context/NotificationContextProvider";
import ProfileContextProvider from "./context/profile-context/ProfileContextProvider";
import ReviewContextProvider from "./context/review-context/ReviewContextProvider";
import SidenavContextProvider from "./context/sidenav-context/SidenavContextProvider";
import TripContextProvider from "./context/trip-context/TripContextProvider";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthContextProvider>
    <ItineraryContextProvider>
      <ProfileContextProvider>
        <ContactContextProvider>
          <NotificationContextProvider>
            <SidenavContextProvider>
              <TripContextProvider>
                <ReviewContextProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </ReviewContextProvider>
              </TripContextProvider>
            </SidenavContextProvider>
          </NotificationContextProvider>
        </ContactContextProvider>
      </ProfileContextProvider>
    </ItineraryContextProvider>
  </AuthContextProvider>
);
