import { ReviewVotesAPIModel } from "@safe-traveler/safe-traveler-data-models";
import { APIManager } from "./APIManager";

export class ReviewVotesAPI extends APIManager {
  // GET /review-votes
  public static async getAllReviewVotes(): Promise<ReviewVotesAPIModel[]> {
    console.log("HERE");

    const response = await this.getRequest("/v1/review-votes");
    return response.data as ReviewVotesAPIModel[];
  }

  // PUT /review-votes
  public static async putReviewVote(reviewVote: ReviewVotesAPIModel) {
    const response = await this.putRequest(
      "/v1/review-votes",
      {},
      JSON.stringify(reviewVote)
    );
    return response;
  }

  // DELETE /review-votes/{review-id}
  public static async deleteReviewVotes(reviewID: string) {
    const response = await this.deleteRequest(`/v1/review-votes/${reviewID}`);
    return response;
  }

  // GET /review-votes/{review-id}
  public static async getReviewVotes(
    reviewID: string
  ): Promise<ReviewVotesAPIModel> {
    const response = await this.getRequest(`/v1/review-votes/${reviewID}`);
    return response.data as ReviewVotesAPIModel;
  }
}
