import { Dispatch } from 'react';

/**
 * Core types required for notification context to function
 */
// state and dispatch pointers that are exposed by the notification context.
export interface NotificationContextValue {
  notificationState: NotificationContextState;
  notificationDispatch: NotificationContextDispatch;
}

// Contains the data of the notification context.
export interface NotificationContextState {
  notifications: NotificationData[];
}

// Type of reducer dispatch function for notification context.
export type NotificationContextDispatch = Dispatch<NotificationContextReducerAction>;

// Input for notification context dispatch funcntion.
export interface NotificationContextReducerAction {
  type: NotificationContextActionTypes;
  payload?: any,
}

export enum NotificationContextActionTypes {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
}

/**
 * Types used in notification context state
 */
export interface NotificationData {
  id: string;
  ttl?: number;
  value: string;
}
