import { Banner, Button, Icon, PageContent } from '@jam-dev/starlight-react';
import { TripView } from '@safe-traveler/react-component-lib';
import { TripAPIModel } from '@safe-traveler/safe-traveler-data-models';
import { Fragment, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import linkIcon from '../../assets/icons/link.svg';
import mapMarkerIcon from '../../assets/icons/map-marker.svg';
import teamUpIllustraction from '../../assets/illustration/undraw_team_up.svg';
import travelingIllustraction from '../../assets/illustration/undraw_traveling.svg';
import Footer from '../../Components/common/footer/Footer';
import { ContactContext } from '../../context/contact-context/ContactContext';
import { TripContext } from '../../context/trip-context/TripContext';
import './HomePage.css';

export const HomePage = () => {
  const { tripState } = useContext(TripContext.getContext());
  const { contactState } = useContext(ContactContext.getContext());
  const navigate = useNavigate();

  const renderTripView = (tripList: TripAPIModel[]) => {
    if (tripList.length > 0) {
      const currentTrip = tripList[0];
      const startTime = new Date(currentTrip.startTime);
      const endTime = new Date(currentTrip.endTime);

      return (
        <TripView
          contacts={contactState.contacts.filter(e => currentTrip.contacts.includes(e.contactId)).map(e => `${e.firstName} ${e.lastName}`)}
          dateTime={`${startTime.toDateString()} ${startTime.getHours()}:00 to ${endTime.toDateString()} ${endTime.getHours()}:00`}
          locationName={currentTrip.tripCity}
          onEdit={handleEdit}
          tripID={currentTrip.tripID}
          tripName={currentTrip.tripName}
        />
      );
    }
    else {
      return (
        <Banner className='home-page-trips-banner'>
          <Icon alt='Location icon' src={mapMarkerIcon} />
          <p>No upcoming trips. Add trips in the Trips page.</p>
          <Link to='/trips'>
            <Button>
              <Icon alt='To trips page' src={linkIcon} />
              <p>Trips page</p>
            </Button>
          </Link>
        </Banner>
      );
    }
  }

  const handleEdit = (tripID: string) => {
    navigate('/trips');
  }

  return (
    <Fragment>
      <PageContent>
        <h1>Welcome to Safe Traveler</h1>
        <h2>Upcoming trip</h2>
        <img alt='Trip art' className='home-page-illustration' src={travelingIllustraction} />
        { renderTripView(tripState.tripList) }
        <hr />
        <h1>Manage</h1>
        <img alt='Trip art' className='home-page-illustration' src={teamUpIllustraction} />
        <div className='home-page-manage-buttons'>
          <Link to='/trips'><Button>Manage trips</Button></Link>
          <Link to='/contacts'><Button>Manage contacts</Button></Link>
          <Link to='/reviews'><Button>Manage reviews</Button></Link>
        </div>
      </PageContent>
      <Footer />
    </Fragment>
  )
}
