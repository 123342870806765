import { ProfileAPIModel } from '@safe-traveler/safe-traveler-data-models';
import { Dispatch } from 'react';

/**
 * Core types required for profile context to function
 */
// state and dispatch pointers that are exposed by the profile context.
export interface ProfileContextValue {
  profileState: ProfileContextState;
  profileDispatch: ProfileContextDispatch;
}

// Contains the data of the profile context.
export interface ProfileContextState {
  profile: ProfileAPIModel[]
}

// Type of reducer dispatch function for profile context.
export type ProfileContextDispatch = Dispatch<
  ProfileContextAddProfileAction |
  ProfileContextDeleteProfileAction |
  ProfileContextEditProfileAction
>;

// Inputs for profile context dispatch function.
export interface ProfileContextAddProfileAction {
  type: ProfileContextActionTypes.ADD_PROFILE,
  payload: ProfileAPIModel
}

export interface ProfileContextEditProfileAction {
  type: ProfileContextActionTypes.EDIT_PROFILE,
  payload: ProfileAPIModel
}

export interface ProfileContextDeleteProfileAction {
  type: ProfileContextActionTypes.DELETE_PROFILE,
  payload: string
}

export enum ProfileContextActionTypes {
  ADD_PROFILE,
  DELETE_PROFILE,
  EDIT_PROFILE
}