import { ReactNode, useContext } from "react";
import { Link, Route, Routes } from "react-router-dom";
import "./App.css";
// import AddContacts from "./Components/contacts/AddContacts";
// import Contacts from "./Components/contacts/Contacts";
// import ModifyContact from "./Components/contacts/ModifyContact";
// import Home from "./Components/home/Home.js";
// import AddEvent from "./Components/itinerary/AddEvent.js";
// import DeleteEvent from "./Components/itinerary/DeleteEvent.js";
// import DynamicAdd from "./Components/itinerary/DynamicAdd";
// import Itinerary from "./Components/itinerary/Itinerary.js";
// import ModifyEvent from "./Components/itinerary/ModifyEvent.js";
// import Login from "./Components/login/Login.js";
// import Pin from "./Components/Pin.js";
// import EditProfile from "./Components/profiles/EditProfile.js";
// import Profile from "./Components/profiles/Profile.js";
// import ProfileReviews from "./Components/profiles/ProfileReviews";
// import Signup from "./Components/profiles/Signup.js";
// import AddReview from "./Components/reviews/AddReview.js";
// import EditReview from "./Components/reviews/EditReview";
// import ContactView from "./Components/route/contact-view/ContactView";
// import DevPage from "./Components/route/dev-page/DevPage";
// import Map from "./Components/route/map-page/MapPage";
// import SettingsPage from "./Components/route/settings-page/SettingsPage";
// import ShareTrip from "./Components/ShareTrip.js";
// import AddTrip from "./Components/trips/AddTrip.js";
// import CurrentTrip from "./Components/trips/CurrentTrip.js";
// import ModifyTrip from "./Components/trips/ModifyTrip.js";
// import TripHistory from "./Components/trips/TripHistory";
// import Trips from "./Components/trips/Trips.js";
// import ViewURLs from "./Components/ViewURLs.js";
import { Icon, Page } from '@jam-dev/starlight-react';
import { SidenavFooter } from "./Components/sidenav-footer/SidenavFooter";
import { SidenavContext } from "./context/sidenav-context/SidenavContext";
import { SidenavContextActionTypes } from "./context/sidenav-context/SidenavContextModels";
import { PageData, pages } from "./data/Pages";

function App() {
  const {sidenavState, sidenavDispatch} = useContext(SidenavContext.getContext());

  const registerRootPages = (pageDataList: PageData[]) => {
    const routeElements: ReactNode[] = [];

    pageDataList.forEach(pageData => {
      routeElements.push(
        <Route
          element={pageData.component}
          key={pageData.pageRoute}
          path={pageData.pageRoute}
        />
      );
    });

    return routeElements;
  }

  const renderSidenavContent = (pageDataList: PageData[]) => {
    const sidenavLinks: ReactNode[] = [];

    pageDataList.forEach(pageData => {
      if (pageData.sidenavConfig) {
        sidenavLinks.push(
          <Link
            className='page-sidenav-link'
            key={pageData.pageRoute}
            onClick={mobileCloseSidenav}
            to={pageData.pageRoute}
          >
            <Icon alt='Sidenav link icon' src={pageData.sidenavConfig.icon} />
            <p>{pageData.sidenavConfig.pageName}</p>
          </Link>
        );
      }
    });

    return sidenavLinks;
  }

  const mobileCloseSidenav = () => {
    if (window.innerWidth >= 768) return;
    closeSidenav();
  }

  const closeSidenav = () => {
    sidenavDispatch({type: SidenavContextActionTypes.CLOSE_SIDENAV});
  }

  const toggleSidenav = () => {
    sidenavDispatch({type: SidenavContextActionTypes.TOGGLE_SIDENAV});
  }

  return (
    <Page
      navbarContent={<Link className='page-home-link' to='/'><span>Safe Traveler</span></Link>}
      onCloseSidenav={closeSidenav}
      onToggleSidenav={toggleSidenav}
      showSidenav={sidenavState.isOpen}
      sidenavContent={renderSidenavContent(pages)}
      sidenavFooter={<SidenavFooter />}
    >
      <Routes>
        { registerRootPages(pages) }
        {/* <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="addContacts" element={<AddContacts />} />
        <Route path="map" element={<Map />} />
        <Route path="profile" element={<Profile />} />
        <Route path="editProfile" element={<EditProfile />} />
        <Route path="addTrip" element={<AddTrip />} />
        <Route path="modifyTrip" element={<ModifyTrip />} />
        <Route path="trips" element={<Trips />} />
        <Route path="contacts/:contactId" element={<ModifyContact />} />
        <Route path="trip/current" element={<CurrentTrip />} />
        <Route path="shareTrip/:id" element={<ShareTrip />} />
        <Route path="createUrl" element={<Pin />} />
        <Route path="viewUrls" element={<ViewURLs />} />
        <Route path="dev-page" element={<DevPage />} />
        <Route path="contact-view" element={<ContactView profileId={""} tripId={""} />} />
        <Route path="profile/reviews/:reviewId" element={<EditReview />} />
        <Route path="addReview" element={<AddReview />} />
        <Route path="tripHistory" element={<TripHistory />} />
        <Route path="addEvent" element={<AddEvent />} />
        <Route path="profile/reviews" element={<ProfileReviews />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="modifyEvent" element={<ModifyEvent />} />
        <Route path="deleteEvent" element={<DeleteEvent />} />
        <Route path="itinerary" element={<Itinerary />} />
        <Route path="dynamicAdd" element={<DynamicAdd />} /> */}
      </Routes>
    </Page>
  );
}

export default App;
